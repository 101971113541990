import { AppContextInitialState } from "../Helpers/Enums";
import { AppReducerActions } from "../ReducerActions/AppReducerActions";
import {
  BREADCRUMB_PAGE_ROUTES,
  PAGE_ROUTE_ID,
  BREADCRUM_TYPE,
} from "../Helpers/Enums";

const InitialState = {
  ...AppContextInitialState,
  breadCrumbs: BREADCRUMB_PAGE_ROUTES.slice().sort((a, b) => a.id - b.id),
};

const _Breadcrumbs = BREADCRUMB_PAGE_ROUTES.sort((a, b) => a.id - b.id);
const defaultBrdCrmb = BREADCRUMB_PAGE_ROUTES.sort((a, b) => a.id - b.id);
export const AppReducer = (state = InitialState, action) => {
  switch (action.type) {
    case AppReducerActions.SHOW_TOAST: {
      const { message, toastType } = action;
      return {
        ...state,
        showToast: !state.showToast,
        toastMessage: message,
        toastType,
      };
    }
    case AppReducerActions.SET_PROFILE_IMAGE: {
      const { imagePath } = action;
      return {
        ...state,
        profileImage: imagePath,
      };
    }

    case AppReducerActions.SET_PROJECTID: {
      const { projectId, projectName } = action;
      return {
        ...state,
        projectId: projectId,
        projectName: projectName,
      };
    }

    case AppReducerActions.SET_REPORTID: {
      const { reportId, reportDate } = action;
      return {
        ...state,
        reportId: reportId,
        reportDate: reportDate,
      };
    }
    case AppReducerActions.SET_ACTIONITEM_ID: {
      const { actionItemId, actionItemName } = action;
      return {
        ...state,
        actionItemId: actionItemId,
        actionItemName: actionItemName,
      };
    }
    case AppReducerActions.SET_ROLEID: {
      const { roleId } = action;
      return {
        ...state,
        roleId: roleId,
      };
    }
    case AppReducerActions.LOGOUT: {
      const { userId } = action;
      return {
        ...state,
        userId: userId,
      };
    }
    case AppReducerActions.SET_ACTION_ITEM_CLEAR: {
      const { actionItemClear } = action;
      return {
        ...state,
        actionItemClear: actionItemClear,
      };
    }

    case AppReducerActions.TOGGLE_LOADER: {
      const { value } = action;
      return { ...state, isLoading: value };
    }

    case AppReducerActions.UPDATE_BREADCRUMBS: {
      const { pageName, projectName, title } = action;
      const pageRoutes = PAGE_ROUTE_ID;
      const arrPageName = pageName.split("/");

      var brdCrumbList = [...defaultBrdCrmb];

      brdCrumbList = brdCrumbList.map((x) => {
        if (x.id === pageRoutes.OVERVIEW) {
          x.pageName = projectName;
        }
        // else if(x.id === pageRoutes.CREATE_ACTION) {
        //   x.pageName = projectName;
        // }

        else if (x.id === pageRoutes.EDIT_ACTION_ITEM) {
          x.pageName = title;
        }
////
if (x.id === pageRoutes.ESTIMATION) {
  
  x.pageName = `Estimation ${title}`;
}
        return x;
      });

      const selectedPageIndex = brdCrumbList.findIndex(
        (t) => t.route === `/${arrPageName[1]}`
      );
      
      const selectedBreadCrumb = brdCrumbList[selectedPageIndex];
      return {
        ...state,
        breadCrumbs: brdCrumbList.map((page, index) => {
          if (selectedPageIndex === 3 || selectedPageIndex === 5) {
            // From header if u click time logging/ employees do not show manage project
            // Set isActive to false for all pages except the one at selectedPageIndex
            return {
              ...page,
              isActive: index === selectedPageIndex,
              isSelected: index === selectedPageIndex,
            };
          }
///
        //  else if (selectedPageIndex === 15 ) {
        //     // From header if u click time logging/ employees do not show manage project
        //     // Set isActive to false for all pages except the one at selectedPageIndex
        //     return {
        //       ...page,
        //       isActive: index === selectedPageIndex,
        //       isSelected: index === selectedPageIndex,
        //     };
        //   }
        else if (
          (selectedPageIndex === 16 )
          // &&
         // [15].includes(index)
        ) {
          //EP--> Time logging/Edit || Time logging/Add
          // Set isActive to true only for indices 5, 6, or 7 when selectedPageIndex is 6 or 7
          return {
            ...page,
            isActive: index === selectedPageIndex ,
            isSelected: index === selectedPageIndex,
          };
        }
///
          else if
            (selectedPageIndex === 10 && [4, 9, 8, 11, 12].includes(index)) {
            return { ...page, isActive: false, isSelected: false };
          }


          else if (
            (index > selectedPageIndex &&
              [6, 8, 9, 10, 11, 12].includes(page.id)) ||
            ![
              BREADCRUM_TYPE.DEFAULT,
              selectedBreadCrumb.breadCrumbType,
            ].includes(page.breadCrumbType)
          ) {
            return { ...page, isActive: false, isSelected: false };
          } else if (selectedPageIndex === 9 && [2, 8, 4].includes(index)) {
            //Admin --> Project/Project name/Sprint overview and issue
            // Set isActive to false for specific indices when selectedPageIndex is 9
            return { ...page, isActive: false, isSelected: false };
          } else if (selectedPageIndex === 8 && ![0, 8].includes(index)) {
            // Show only index 0 and 8 when selectedPageIndex is 8, hide the rest
            return { ...page, isActive: false, isSelected: false };
          } else if (
            (selectedPageIndex === 6 || selectedPageIndex === 7) &&
            [0, 5, 6, 7, 15].includes(index)
          ) {
            //EP--> Time logging/Edit || Time logging/Add
            // Set isActive to true only for indices 5, 6, or 7 when selectedPageIndex is 6 or 7
            return {
              ...page,
              isActive: index === selectedPageIndex || index === 5,
              isSelected: index === selectedPageIndex,
            };
          }
          //SYJ - Fix for Employee, MyTask, RolePermission breadcrumb Issue.
          else if (selectedPageIndex === 14 && [0, 16, 11, 12].includes(index)) {
            return { ...page, isActive: false, isSelected: false };
          }
          else if (selectedPageIndex === 11 && [0, 13, 14, 12].includes(index)) {
            return { ...page, isActive: false, isSelected: false };
          }
          else if (selectedPageIndex === 12 && [0, 14, 11].includes(index)) {
            return { ...page, isActive: false, isSelected: false };
          }
          else if (selectedPageIndex === 13 && [0, 15].includes(index)) {
            return { ...page, isActive: false, isSelected: false };
          }
          else if (selectedPageIndex === 15 && [0, 6, 7].includes(index)) {
            return { ...page, isActive: false, isSelected: false };
          }
          return {
            ...page,
            isActive: true,
            isSelected: index === selectedPageIndex,
          };
        }),
      };
    }

    default:
      return { ...state };
  }
};