import React, { useContext, useEffect, useState } from "react";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";
import {
    HTTPResponse,
    ToastMessageType,
    MaxCharacterCount,
    MISC_OTHER_OPTIONS,
} from "../Helpers/Enums";
import {
    GetLoggedInUserID,
    isStringEmpty,
} from "../Helpers/Utilities";
import { CommonErrorMessages, ToastMessages } from "../Helpers/HelperText";
import ErrorField from "../Components/ErrorField";
import { ReportService } from "../WebApiServices/Report.service";
import Select, { components } from "react-select";
import { ActionItemService } from "../WebApiServices/ActionItem.service";

const AddMiscellenousOther = ({
    isOpen,
    setShowModal,
    isTrainingOtherSelcted,
    isMiscOtherSelected,
    otherMisType,
    isFromAddEditComments,
    loadWorkDoneOptions,
    getWorkItemValue
}) => {
    const { ShowToastMessage } = useContext(AppContext);

    const { LogOut } = useUIContext();
    const [errors, setErrors] = useState({ typeName: false, duplicateMiscOther: false, miscTypeEmpty: false });
    const [typeName, setTypeName] = useState("");
    const [miscTypeOther, setMiscTypeOther] = useState({ value: 0, label: "" });


    // Reset validation errors when modal is closed
    useEffect(() => {
        if (!isOpen) {
            setErrors((prev) => ({
                ...prev,
                typeName: false,
                duplicateMiscOther: false,
                miscTypeEmpty: false
            }));
            setTypeName("");
            setMiscTypeOther({ value: 0, label: "" });
        }
    }, [isOpen]);

    const ontypeNameChange = (e) => {
        const { value } = e.target;
        setTypeName(value);
        setErrors((prev) => ({
            ...prev,
            typeName: isStringEmpty(value),
        }));
    }

    const ValidateField = () => {
        setErrors((prev) => ({
            ...prev,
            typeName: isStringEmpty(typeName),
            duplicateMiscOther: false,
            miscTypeEmpty: (!isFromAddEditComments && miscTypeOther.value === 0),
        }));

        if (isStringEmpty(typeName) || (!isFromAddEditComments && miscTypeOther.value === 0))
            return false;
        else
            return true;
    };

    const SaveMiscOtherType = async () => {
        try {

            if (ValidateField()) {
                let loggedInUser = GetLoggedInUserID();
                // let response = await ReportService.SaveMiscOtherValue(typeName, loggedInUser, isTrainingOtherSelcted, isMiscOtherSelected);

                if (isFromAddEditComments) {
                    let response = await ActionItemService.AddWorkItemType(isFromAddEditComments, typeName);
                    if (response.data > 0) {
                        loadWorkDoneOptions();
                        return getWorkItemValue(response.data)
                        //return setShowModal();
                    }
                    if (response.data === 0) {
                        setErrors((prev) => ({
                            ...prev,
                            duplicateMiscOther: true,
                            //typeName: false
                        }));
                        //loadWorkDoneOptions();
                        //return setShowModal();
                    }
                    if (response.status === HTTPResponse.Unauthorized) {
                        LogOut();
                    }
                }
                else {
                    let response = await ReportService.SaveMiscOtherValue(typeName, loggedInUser, miscTypeOther.value);

                    if (response.data === "DuplicateName") {
                        setErrors((prev) => ({
                            ...prev,
                            duplicateMiscOther: true,
                            typeName: false
                        }));
                    }
                    if (response.data != "DuplicateName" && response.status === HTTPResponse.OK) {
                        ShowToastMessage(CommonErrorMessages.Added, ToastMessageType.Success);
                        return setShowModal();
                    }
                    if (response.status === HTTPResponse.Unauthorized) {
                        LogOut();
                    }
                }
            }
        } catch {
            ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
        }
    };

    const handleMiscChange = (selectedOption) => {
        setErrors((prev) => ({
            ...prev,
            miscTypeEmpty: false,
        }));
        setMiscTypeOther(selectedOption);
        setTypeName("");
    }

    return (
        isOpen && (
            <section className="log-hours-modal ">
                <div
                    className="modal custom-depth"
                    id="EditVideoModal"
                    tabIndex={-1}
                    style={{
                        display: "block",
                        paddingRight: "17px",
                        backgroundColor: "#00000094",
                    }}
                    aria-labelledby="ModalFormLabel"
                    aria-hidden="true"
                >
                    <div
                        className="modal-dialog modal-dialog-centered"
                        style={{ maxWidth: "620px" }}
                    >
                        <div className="modal-content"  >
                            <div className="modal-body">
                                <div className="container">
                                    <div className="row modal-headerr">
                                        <div className="col-10 col-md-10 col-lg-10">
                                            {/* <label className="log-work-label">Miscellaneous - {otherMisType !== "Other" ? otherMisType + " - Other" : "Other"}</label> */}
                                            <label className="log-work-label">{isFromAddEditComments ? "Add Other" : "Miscellaneous - Other"}</label>
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-2">
                                            <button
                                                type="button"
                                                className="btn-close btn-close-white"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => {
                                                    return setShowModal();
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="log-hours-main">
                                        <div className="modal-body">
                                            <div className="row content">
                                                {!isFromAddEditComments && (<div className="col-12 col-md-6 col-lg-6 mt-3 custom-padding">
                                                    <label>Miscellaneous Type {" "}<span className="mandatory-field">*</span></label>
                                                    <Select
                                                        name="miscList"
                                                        value={miscTypeOther.value > 0 ? miscTypeOther : null}
                                                        placeholder="Please Select"
                                                        options={MISC_OTHER_OPTIONS}
                                                        //  value={miscValue} // Set the selected value
                                                        onChange={handleMiscChange} // Call the handler function on change                                                       
                                                    />
                                                    <ErrorField
                                                        show={errors.miscTypeEmpty}
                                                        message={CommonErrorMessages.MiscOtherType}
                                                    />
                                                </div>)}
                                                <div className=" col-lg-12 col-md-12 col-sm-12" style={{ marginTop: "20px" }}>
                                                    <div>
                                                        {/* <label htmlFor="todate">Add Value for {otherMisType !== "Other" ? otherMisType + " - Other" : "Other"}</label> */}
                                                        <label htmlFor="todate">Add Value for Other {" "}<span className="mandatory-field">*</span></label>
                                                        <br />
                                                        <input
                                                            type="typeName"
                                                            className="form-control"
                                                            id="typeName"
                                                            name="typeName"
                                                            placeholder="Enter Type Name"
                                                            value={typeName}
                                                            onChange={ontypeNameChange}
                                                            maxLength={50}
                                                        />
                                                    </div>

                                                    {errors.typeName &&
                                                        <ErrorField
                                                            show={errors.typeName}
                                                            message={CommonErrorMessages.MiscOther}
                                                        />}
                                                    {errors.duplicateMiscOther &&
                                                        <ErrorField
                                                            show={errors.duplicateMiscOther}
                                                            message={CommonErrorMessages.MiscOtherSameVal}
                                                        />}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="log-cancel-div"
                                            style={{ padding: "0px !important" }}
                                        >
                                            <button
                                                type="button"
                                                onClick={SaveMiscOtherType}
                                                className="log-btn"
                                                style={{ marginRight: "12px" }}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    );
};

export default AddMiscellenousOther;
