
import React from "react";
const ContextMenuComponent = ({ handleDelete, contextMenuPositionX, contextMenuPositionY
}) => {
    return (
        <div className="contex-menu">
            <div style={{ top: contextMenuPositionY - 100 + "px", left: contextMenuPositionX + 150 + "px", cursor: "pointer" }} onClick={handleDelete}>
                <span className="delete-context">Delete</span>
            </div>
        </div>);

}

export default ContextMenuComponent;