import React, { useState, useEffect, useContext } from "react";
import SprintOverview from "../SprintOverview";
import ActiveSprints from "../Overview/ActiveSprints";
import CompletedSprint from "../CompletedSprint";
import BreadcrumbComponent from "../../Components/BreadcrumbComponent";
import { AddLocalStorageData, GetLocalStorageData, RemoveLocalStorageData, changePageTitle } from "../../Helpers/Utilities";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import TshirtSize from "./TShirtSize";
import General from "./General";
import { HTTPResponse, ROLE_TYPE, ToastMessageType } from "../../Helpers/Enums";
import ErrorPage from "../ErrorPage";
import Coding from "./Coding";
import Database from "./Database";
import Testing from "./Testing";
import { AppContext } from "../../Contexts/AppContextProvider";
import { GlobalEstimationService } from "../../WebApiServices/GlobalEstimation.service";
import { ToastMessages } from "../../Helpers/HelperText";


const GlobalEstimation = () => {
  const {ShowToastMessage,ToggleLoader}=useContext(AppContext);

  const location = useLocation();
  const navigate = useNavigate();
  const { sprintNames, sprint_Ids } = location.state || {};
  const [activeTab, setActiveTab] = useState(() => {
    return GetLocalStorageData("activeTabGlobalEstimation") || "T-Shirt Size";
  });
  const [tshirtSizeList,setTshirtSizeList]=useState([]);
  const pageParams = useParams();
  const projectId = pageParams.projectId;
  //const [tabName,setTabName]=useState("TshirtSize");
  const roleId = GetLocalStorageData("roleID");
  const NavigateToCreateActionItem = () => {
    //navigate(`/CreateActionItem/${projectId}`);
    let storedSprint = GetLocalStorageData('selectedSprint');
    if (storedSprint !== undefined && storedSprint !== null && storedSprint !== "Uwxt7s/r5zp6v/kfF4wOgw==" && storedSprint && activeTab === "Active Sprints") {
      storedSprint = JSON.parse(storedSprint);
      //console.log("sprintNames: storedSprint.label, sprint_Ids: storedSprint.value", storedSprint.label, storedSprint.value)
      navigate(`/AddActionItem/${projectId}`, {
        state: { sprintNames: storedSprint.label, sprint_Ids: storedSprint.value },
      });
    }
    else {
      RemoveLocalStorageData("selectedSprint");
      navigate(`/AddActionItem/${projectId}`);
     }
  };
const getTshirtSize=async()=>{
  try{
    const response=await GlobalEstimationService.GetTshirtSize();
    if(response.status===HTTPResponse.OK){
        
      setTshirtSizeList(response.data);
    }
  }
  catch (error) {
    ToggleLoader(false);
    ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
}
}
  const handleTabClick = (tabName) => {
      
   // setTabName(tabName);
    setActiveTab(tabName);
    changePageTitle(tabName);
    AddLocalStorageData("activeTabGlobalEstimation", tabName);
  };

  useEffect(() => {
    changePageTitle(activeTab);
  }, [activeTab]);
  useEffect(()=>{
    getTshirtSize();
  },[])
  const projectName = GetLocalStorageData("projectName");
  const localStorageId = GetLocalStorageData("key");

// const handleNameUpdate = (name) => {
//   setTabName(name);
// };
  return (
    <>
    {roleId===ROLE_TYPE.ADMIN?
      <div className="main-body-content overview global-estimation">
        <title>Overview</title>
        
        <section className="breadcrumb-block">
        {/* <BreadcrumbComponent projectName={uniqueProjectName?uniqueProjectName:projectName}  /> */}
        <BreadcrumbComponent  title={activeTab}  />
        </section>

        <div className="overview-main-block" style={{ position: 'sticky', top: '90px', zIndex: '99', paddingTop: '11px', backgroundColor: "#f1f4f6" }}>
          <div>
            <div className="overview-tabs">
              <div>
                <ul
                  className="nav nav-tabs justify-content-center"
                  id="myTab"
                  role="tablist"
                >
                  <TabButton
                    tabId="T-Shirt Size"
                    activeTab={activeTab}
                    title="T-Shirt Size"
                    onClick={handleTabClick}
                  />
                  <TabButton
                    tabId="General"
                    activeTab={activeTab}
                    title="General"
                    onClick={handleTabClick}
                  />
                  <TabButton
                    tabId="Coding"
                    activeTab={activeTab}
                    title="Coding"
                    onClick={handleTabClick}
                  />
                  <TabButton
                    tabId="Database"
                    activeTab={activeTab}
                    title="Database"
                    onClick={handleTabClick}
                  />
                  <TabButton
                    tabId="Testing"
                    activeTab={activeTab}
                    title="Testing"
                    onClick={handleTabClick}
                  />
                </ul>
              </div>
              {/* <div>
                {localStorageId && (<button
                  className="create-btn"
                  style={{ marginRight: "15px" }}
                  onClick={NavigateToCreateActionItem}
                  title="Add Work Item"
                >
                  <span style={{ color: "white", fontWeight: '500' }}>Add Work Item</span>
                </button>
                )}

              </div> */}
            </div>
          </div>
        </div>

        <div className="tab-content global-estimation" id="myTabContent">
          <TabContent tabId="T-Shirt Size" activeTab={activeTab}>
            {activeTab === "T-Shirt Size" && <TshirtSize getTshirtSize={getTshirtSize}
            //projectId={projectId} onNameChange={handleNameUpdate}
             />}
          </TabContent>
          <TabContent tabId="General" activeTab={activeTab}>
            {activeTab === "General" && <General  tshirtSizeList={tshirtSizeList} projectId={projectId} />}
          </TabContent>
          {/* <TabContent tabId="General" activeTab={activeTab}>
            {activeTab === "General" && <ActiveSprints projectId={projectId} />}
          </TabContent> */}
          <TabContent tabId="Coding" activeTab={activeTab} >
            {activeTab === "Coding" && <Coding projectId={projectId} tshirtSizeList={tshirtSizeList} />}
          </TabContent>
          <TabContent tabId="Database" activeTab={activeTab}>
            {activeTab === "Database" && <Database projectId={projectId}  tshirtSizeList={tshirtSizeList}/>}
          </TabContent>
          <TabContent tabId="Testing" activeTab={activeTab}>
            {activeTab === "Testing" && <Testing projectId={projectId} tshirtSizeList={tshirtSizeList} />}
          </TabContent>
        </div>
      </div>:<ErrorPage/>}
    </>
  );
};

const TabButton = ({ tabId, activeTab, title, onClick }) => (
  <li className="nav-item" role="presentation">
    <button
      className={`nav-link tab-title ${tabId === activeTab ? "active" : ""}`}
      id={`${tabId}-tab`}
      data-bs-toggle="tab"
      data-bs-target={`#${tabId}`}
      type="button"
      role="tab"
      aria-controls={tabId}
      aria-selected={tabId === activeTab}
      onClick={() => onClick(tabId)}
    >
      {title}
    </button>
  </li>
);

const TabContent = ({ tabId, activeTab, children }) => (
  <div
    className={`tab-pane fade ${tabId === activeTab ? "active show" : ""}`}
    id={tabId}
    role="tabpanel"
    aria-labelledby={`${tabId}-tab`}
  >
    {children}
  </div>
);

export default GlobalEstimation;
