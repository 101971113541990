import React from "react";

const DeleteModal = ({
  showDeleteModal,
  onYesClick,
  onNoClick,
  textToDisplay,
  sprintConfirmModal,
  sprintDetails,
  rootCause, isFromWorkDone,
  isDirtyModal,globalEstimation
}) => {
  // className="col-12 col-md-6 col-lg-6 mt-3 custom-padding"
  return (
    <>
      {
        rootCause ? (
          <div className="user-management-modal move-item-modal">
            <div
              className="modal fade show"
              id="userManagementModal"
              tabIndex={-1}
              aria-labelledby="ModalFormLabel"
              aria-hidden="true"
              style={{
                display: "block",
                backgroundColor: "#00000094",
                zIndex: "999!important",
              }}
            >
              <div className="modal-dialog modal-dialog-centered modal-dialog-custom-root-cause">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="container">
                      <div className="row modal-headerr">
                        <div className="col-10 col-md-10 col-lg-10">
                          <label className="modal-head-label">
                            Root Cause
                          </label>
                        </div>
                        <div className="col-2 col-md-2 col-lg-2">
                          <button
                            type="button"
                            className="btn-close btn-close-white"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={onNoClick}
                          />
                        </div>
                      </div>
                      {/* <div className="user-management-main move-item-main"> */}
                      <div className="user-management-main root-cause-main">
                        <div
                          className="modal-body"
                          style={{ marginBottom: "0px" }}
                        >
                          {/* <div className="alert-msg-sec">
                            <img
                              className="alert-icon"
                              src=" Assets/images/Alert.png"
                              alt=""
                            />{" "}
                            <span>
                              Sprint scope will be affected by this action
                            </span>{" "}
                          </div> */}
                          {/* <div className="mt-4">
                            <strong>{sprintDetails.key}</strong> will be moved
                            from sprint{" "}
                            <strong>{sprintDetails.previousSprint}</strong> to
                            sprint <strong>{sprintDetails.newSprint}</strong>
                          </div> */}

                          {/* <div className="text-center yes-no-btn">
                                      <div > <button type="button" className="btn btn-background-color yes-btn" onClick={onYesClick}>Yes</button></div>
                                      &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                                      <div ><button type="button" className="btn btn-background-color  yes-btn" onClick={onNoClick}>No</button></div>
                                  </div> */}

                          {/* Code Issues:Occurs when the implemented module's functionality stops working as intended. Arises when the code's logic is misunderstood, resulting in unexpected behavior or incorrect outcomes. Merge Issues: These occur during code integration between branches and involve missing components during the merge process. Data Issues: Involves discrepancies between displayed data on the user interface and actual data.Compatibility Issues: Arise when code doesn't run smoothly across different systems, platforms, or environments due to varying configurations. Requirement Miss: Result from inadequate consideration of requirements, not documented correctly leading to unaddressed impacts on multiple areas affected by new changes.Incorrect Requirement Specifications: Arises when the requirements are misunderstood by the developer.Network issues: Network issues can have a significant impact on the performance, functionality, and reliability of software applications or systems that rely on network connectivity. I.e. Load balance, internet issue, server issue.Performance Issues: Usually happens when user request and response take time to load the data. It could be due to various reasons such as Connectivity etc.Responsive issues: Responsive issues occur when the design and layout of a website or application don't adapt properly to these different screen sizes, leading to elements overlapping, text becoming unreadable, images not fitting correctly, and overall poor user experience. Security Issues: Arise from the unauthorized access of confidential data, allowing users lacking proper authority to gain entry. Environmental Issues: Works on Dev and not on UAT or Prod, or works on UAT and not on Prod. Tester Error: Due to testing incorrectly                */}
                          {/* Code Issues: 

  Occurs when the implemented module's functionality stops working as intended. 

  Arises when the code's logic is misunderstood, resulting in unexpected behavior or incorrect outcomes. 

Merge Issues: These occur during code integration between branches and involve missing components during the merge process. 

Data Issues: Involves discrepancies between displayed data on the user interface and actual data. 

Compatibility Issues: Arise when code doesn't run smoothly across different systems, platforms, or environments due to varying configurations. 

Requirement Miss: Result from inadequate consideration of requirements, not documented correctly leading to unaddressed impacts on multiple areas affected by new changes. 

Incorrect Requirement Specifications: Arises when the requirements are misunderstood by the developer. 

Network issues: Network issues can have a significant impact on the performance, functionality, and reliability of software applications or systems that rely on network connectivity. I.e. Load balance, internet issue, server issue. 

Performance Issues: Usually happens when user request and response take time to load the data. It could be due to various reasons such as Connectivity etc. 

Responsive issues: Responsive issues occur when the design and layout of a website or application don't adapt properly to these different screen sizes, leading to elements overlapping, text becoming unreadable, images not fitting correctly, and overall poor user experience. 

Security Issues: Arise from the unauthorized access of confidential data, allowing users lacking proper authority to gain entry. 

Environmental Issues: Works on Dev and not on UAT or Prod, or works on UAT and not on Prod. 

Tester Error: Due to testing incorrectly  */}
                          <div>
                            <p><strong>Code Issues:</strong><br />
                              Occurs when the implemented module's functionality stops working as intended.<br />
                              Arises when the code's logic is misunderstood, resulting in unexpected behavior or incorrect outcomes.</p>

                            <p><strong>Merge Issues:</strong><br />
                              These occur during code integration between branches and involve missing components during the merge process.</p>

                            <p><strong>Data Issues:</strong><br />
                              Involves discrepancies between displayed data on the user interface and actual data.</p>

                            <p><strong>Compatibility Issues:</strong><br />
                              Arise when code doesn't run smoothly across different systems, platforms, or environments due to varying configurations.</p>

                            <p><strong>Requirement Miss:</strong><br />
                              Result from inadequate consideration of requirements, not documented correctly leading to unaddressed impacts on multiple areas affected by new changes.</p>

                            <p><strong>Incorrect Requirement Specifications:</strong><br />
                              Arises when the requirements are misunderstood by the developer.</p>

                            <p><strong>Network Issues:</strong><br />
                              Network issues can have a significant impact on the performance, functionality, and reliability of software applications or systems that rely on network connectivity. I.e. Load balance, internet issue, server issue.</p>

                            <p><strong>Performance Issues:</strong><br />
                              Usually happens when user request and response take time to load the data. It could be due to various reasons such as Connectivity etc.</p>

                            <p><strong>Responsive Issues:</strong><br />
                              Responsive issues occur when the design and layout of a website or application don't adapt properly to these different screen sizes, leading to elements overlapping, text becoming unreadable, images not fitting correctly, and overall poor user experience.</p>

                            <p><strong>Security Issues:</strong><br />
                              Arise from the unauthorized access of confidential data, allowing users lacking proper authority to gain entry.</p>

                            <p><strong>Environmental Issues:</strong><br />
                              Works on Dev and not on UAT or Prod, or works on UAT and not on Prod.</p>

                            <p><strong>Tester Error:</strong><br />
                              Due to testing incorrectly.</p>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) :
        globalEstimation ? (
          <div className="user-management-modal move-item-modal">
          <div
            className="modal fade show"
            id="userManagementModal"
            tabIndex={-1}
            aria-labelledby="ModalFormLabel"
            aria-hidden="true"
            style={{
              display: "block",
              backgroundColor: "#00000094",
              zIndex: "999!important",
            }}
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-custom">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="container">
                    <div className="row modal-headerr">
                      <div className="col-10 col-md-10 col-lg-10">
                        <label className="modal-head-label">
                          Save Changes
                        </label>
                      </div>
                      <div className="col-2 col-md-2 col-lg-2">
                        <button
                          type="button"
                          className="btn-close btn-close-white"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={onNoClick}
                        />
                      </div>
                    </div>
                    <div className="user-management-main move-item-main">
                      <div
                        className="modal-body"
                        style={{ marginBottom: "0px" }}
                      >
                        <div className="alert-msg-sec" style={{padding:"20px 3%"}}>
                          <img
                            className="alert-icon"
                            src=" Assets/images/Alert.png"
                            alt=""
                          />{" "}
                          <span>
                          The changes will affect all associated projects. Would you like to proceed?
                          </span>{" "}
                        </div>
                        <div className="mt-4">
                          {/* <strong>{sprintDetails.key}</strong> will be moved */}
                          {/* from sprint{" "} */}
                          {/* <strong>{sprintDetails.previousSprint}</strong> to */}
                          {/* sprint <strong>{sprintDetails.newSprint}</strong> */}
                        </div>

                        {/* <div className="text-center yes-no-btn">
                            <div > <button type="button" className="btn btn-background-color yes-btn" onClick={onYesClick}>Yes</button></div>
                            &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                            <div ><button type="button" className="btn btn-background-color  yes-btn" onClick={onNoClick}>No</button></div>
                        </div> */}
                      </div>
                    </div>
                    <div className="save-cancel-div">
                      <button
                        type="button"
                        className="confirm-btn"
                        onClick={onYesClick}
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        className="cancel-btn"
                        onClick={onNoClick}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        ) :
          isDirtyModal ? (
            (
              <div className="user-management-modal move-item-modal">
                <div
                  className="modal fade show"
                  id="userManagementModal"
                  tabIndex={-1}
                  aria-labelledby="ModalFormLabel"
                  aria-hidden="true"
                  style={{
                    display: "block",
                    backgroundColor: "#00000094",
                    zIndex: "999!important",
                  }}
                >
                  <div className="modal-dialog modal-dialog-centered modal-dialog-custom">
                    <div className="modal-content" style={{ maxWidth: "470px" }}>
                      <div className="modal-body">
                        <div className="container" style={{ padding: "5px" }}>
                          <div className="user-management-main move-item-main">
                            <div
                              className="modal-body"
                              style={{ marginBottom: "0px", marginTop: "5px" }}
                            >
                              <div className="alert-dirty-msg-sec">
                                <img
                                  className="alert-icon"
                                  src=" Assets/images/alert-icon.png"
                                  alt=""
                                />{" "}
                                <div>
                                  You are navigating to another page. Please <b>Save</b> before you continue.
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                          <div className="save-cancel-div" style={{ padding: "0px", marginRight: "35px" }}>
                            <button
                              type="button"
                              className="confirm-btn"
                              onClick={onYesClick}
                            >
                              OK
                            </button>
                            <button
                              type="button"
                              className="cancel-btn"
                              onClick={onNoClick}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          ) :
            !sprintConfirmModal && !isDirtyModal ? (
              <section>
                <div className="deletedoc">
                  <div
                    className="modal-backdrop fade show"
                    style={{ display: "block" }}
                  ></div>
                  <div
                    className="modal modal-depth"
                    id="deleteDoc-modal"
                    style={{ display: showDeleteModal ? "block" : "none" }}
                  >
                    <div className="modal-dialog modal-l modal-dialog-centered modal-box">
                      <div className="modal-content box">
                        <div className="modal-body" style={{ marginBottom: "0px" }}>
                          <div className="block1">{textToDisplay}</div>
                          <div className="text-center yes-no-btn">
                            <div>
                              {" "}
                              <button
                                type="button"
                                className="btn btn-background-color yes-btn"
                                onClick={onYesClick}
                              >
                                Yes
                              </button>
                            </div>

                            <div>
                              <button
                                type="button"
                                className="btn btn-background-color  no-btn"
                                onClick={onNoClick}
                              >
                                No
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) :
              (
                <div className="user-management-modal move-item-modal">
                  <div
                    className="modal fade show"
                    id="userManagementModal"
                    tabIndex={-1}
                    aria-labelledby="ModalFormLabel"
                    aria-hidden="true"
                    style={{
                      display: "block",
                      backgroundColor: "#00000094",
                      zIndex: "999!important",
                    }}
                  >
                    <div className="modal-dialog modal-dialog-centered modal-dialog-custom">
                      <div className="modal-content">
                        <div className="modal-body">
                          <div className="container">
                            <div className="row modal-headerr">
                              <div className="col-10 col-md-10 col-lg-10">
                                <label className="modal-head-label">
                                  Move Work Item
                                </label>
                              </div>
                              <div className="col-2 col-md-2 col-lg-2">
                                <button
                                  type="button"
                                  className="btn-close btn-close-white"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={onNoClick}
                                />
                              </div>
                            </div>
                            <div className="user-management-main move-item-main">
                              <div
                                className="modal-body"
                                style={{ marginBottom: "0px" }}
                              >
                                <div className="alert-msg-sec">
                                  <img
                                    className="alert-icon"
                                    src=" Assets/images/Alert.png"
                                    alt=""
                                  />{" "}
                                  <span>
                                    Sprint scope will be affected by this action
                                  </span>{" "}
                                </div>
                                <div className="mt-4">
                                  <strong>{sprintDetails.key}</strong> {sprintDetails.key === "" ? "This task will be moved from sprint" + 
                                  " " :   "will be moved from sprint"}{" "}
                                  <strong>{sprintDetails.previousSprint}</strong> to
                                  sprint <strong>{sprintDetails.newSprint}</strong>
                                </div>

                                {/* <div className="text-center yes-no-btn">
                                    <div > <button type="button" className="btn btn-background-color yes-btn" onClick={onYesClick}>Yes</button></div>
                                    &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                                    <div ><button type="button" className="btn btn-background-color  yes-btn" onClick={onNoClick}>No</button></div>
                                </div> */}
                              </div>
                            </div>
                            <div className="save-cancel-div">
                              <button
                                type="button"
                                className="confirm-btn"
                                onClick={onYesClick}
                              >
                                Confirm
                              </button>
                              <button
                                type="button"
                                className="cancel-btn"
                                onClick={onNoClick}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
    </>
  );
};

export default DeleteModal;
