import React, { useContext, useEffect, useState } from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { GetLoggedInUserID } from "../Helpers/Utilities";
import { LoginService } from "../WebApiServices/Login.service";
import { HTTPResponse } from "../Helpers/Enums";
import { AppContext } from "../Contexts/AppContextProvider";

const PrivateRoute = () => {
  const {  ToggleLoader } = useContext(AppContext);
  const outlet = useOutlet();
  const [isLoggedIn, setIsLoggedIn] = useState(null); // null indicates the loading state
  const currentUrl = window.location.href;

  /**
   * Summary:
   * Date: 05-03-2024
   * Name: Mustakim Shaikh
   * Description: Saves the current URL if the user is not logged in to redirect them back after login.
   */
  useEffect(() => {
    const checkAuthorization = async () => {
      try {
        const response = await LoginService.CheckIfAuthorize();
        if (response.status === HTTPResponse.OK && response.data) {
          setIsLoggedIn(true);
        } else if (response.status === HTTPResponse.Unauthorized) {
          localStorage.setItem("lastVisitedUrl", currentUrl);
          setIsLoggedIn(false);
          ToggleLoader(false);
        }
      } catch (error) {
        console.error("Error checking authorization", error);
        setIsLoggedIn(false);
      }
    };

    checkAuthorization();
  }, [currentUrl]);

  if (isLoggedIn === null) {
    return <div></div>; 
  }

  return isLoggedIn ? outlet : <Navigate to="/" />;
};

export default PrivateRoute;
