import React, { useCallback, useEffect, useState } from "react";
import AGGrid from "../Components/AGGrid";
import Select, { components } from "react-select";

import { ReportService } from "../WebApiServices/Report.service";
import {
  DefaultAGGridProps,
  HTTPResponse,
  MonthNames,
  ROLE_TYPE,
  ToastMessageType,
} from "../Helpers/Enums";
import { useContext } from "react";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";
import {
  GetLocalStorageData, GetLoggedInUserID,
  stripHTMLTags, formatCorrectHours, changePageTitle, WeeklyFormatCorrectHours
} from "../Helpers/Utilities";
import { ToastMessages } from "../Helpers/HelperText";
import BreadcrumbComponent from "../Components/BreadcrumbComponent";
import { useNavigate } from "react-router-dom";

const TimeTracker = () => {
  const { ShowToastMessage, ToggleLoader } = useContext(AppContext);
  const { LogOut } = useUIContext();
  const employeeId = GetLoggedInUserID();
  const navigate = useNavigate();

  const roleId = Number(GetLocalStorageData("userRole"));

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [changeMonth, setChangeMonth] = useState(true);
  const [currentMonthTotal, setCurrentMonthTotal] = useState(0)
  const [load, setLoad] = useState(true);
  const [monthDays, setMonthDays] = useState([]);
  const [newLoad, setNewLoad] = useState(true);
  const [clickedWeekIndex, setClickedWeekIndex] = useState(null);
  const [clickedDayIndex, setClickedDayIndex] = useState(null);
  const [weekDays, setWeekDays] = useState([]);
  const name = GetLocalStorageData("userName");
  const loggedInUserID = GetLocalStorageData("userId");
  const [selectedEmployee, setSelectedEmployee] = useState([
    // roleId ===ROLE_TYPE.EMPLOYEE? {
    //   value: parseInt(loggedInUserID),
    //   label: name,
    // }:""
  ]
  );
  const [selectedProject, setSelectedProject] = useState([
    //{
    //value: 0,
    //label: "All",
    //}
  ]);
  const [selectedDate, setSelectedDate] = useState("");
  const [projects, setProjects] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [dateHours, setDateHours] = useState([]);
  const [firstDay, setFirstDay] = useState("");
  const [lastDay, setLastDay] = useState("");
  const [filteredWorklogDetails, setFilteredWorklogDetails] = useState([]);
  const [responseArray, setResponseArray] = useState([])
  // const goToPreviousMonth = () => {
  //   setCurrentMonth(
  //     (prevMonth) =>
  //       new Date(prevMonth.getFullYear(), prevMonth.getMonth() - 1, 1)
  //   );
  //   setChangeMonth(!changeMonth)
  // };

  // const goToNextMonth = () => {
  //   setCurrentMonth(
  //     (prevMonth) =>
  //       new Date(prevMonth.getFullYear(), prevMonth.getMonth() + 1, 1)
  //   );
  //   setChangeMonth(!changeMonth)
  // };
  const goToPreviousMonth = () => {
    setCurrentMonth(
      (prevMonth) =>
        new Date(prevMonth.getFullYear(), prevMonth.getMonth() - 1, 1)
    );
    setGridOpen(false)
  };

  const goToNextMonth = () => {
    setCurrentMonth(
      (prevMonth) =>
        new Date(prevMonth.getFullYear(), prevMonth.getMonth() + 1, 1)
    );
    setGridOpen(false)

  };

  const fetchDropDownData = async () => {
    try {
      // ToggleLoader(true);
      let response = await ReportService.GetWorklogDropDownData(
        employeeId,
        roleId,
        true, true
      );
      const data = response.data;
      if (response.status === HTTPResponse.OK) {
        setEmployees(data.employees);
        setProjects(data.projects);

        //ToggleLoader(false);
      } else {
        if (response.status === HTTPResponse.Unauthorized) {
          LogOut();
        }
        ToggleLoader(false);
      }
    } catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };

  const generateMonthDays = async () => {
    const daysInMonth = [];
    const firstDayOfMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      1
    );
    const lastDayOfMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1,
      0
    );
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Find the starting day of the week for the first day of the month
    let startingDayOfWeek = firstDayOfMonth.getDay(); // 0 for Sunday, 1 for Monday, etc.

    // Calculate how many days from the previous month need to be added
    let daysFromPreviousMonth =
      startingDayOfWeek === 0 ? 6 : startingDayOfWeek - 1;

    // Create a date for the last day of the previous month
    let lastDayOfPreviousMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      0
    );

    // Add days from the previous month
    for (let i = daysFromPreviousMonth; i > 0; i--) {
      let prevDate = lastDayOfPreviousMonth.getDate() - i + 1;
      let prevFullDate = new Date(
        currentMonth.getFullYear(),
        currentMonth.getMonth() - 1,
        prevDate
      );
      daysInMonth.push({
        date: prevDate,
        day: daysOfWeek[(startingDayOfWeek - i + 7) % 7], // Wrap around for negative indices
        month: lastDayOfPreviousMonth.getMonth() + 1, // Month number for the previous month
        isCurrentMonth: false,
        fullDate: prevFullDate,
      });
    }

    // Start from the first day of the month
    let currentDate = new Date(firstDayOfMonth);

    // Loop until we reach the last day of the month
    while (currentDate <= lastDayOfMonth) {
      const dayObj = {
        date: currentDate.getDate(),
        day: daysOfWeek[currentDate.getDay()],
        month: currentDate.getMonth() + 1, // Month number for the current month
        isCurrentMonth: true,
        fullDate: new Date(currentDate),
      };
      daysInMonth.push(dayObj);

      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
    }


    const remainingDays = 35 - daysInMonth.length;

    for (let i = 1; i <= remainingDays; i++) {
      let nextMonthDate = new Date(
        currentMonth.getFullYear(),
        currentMonth.getMonth() + 1,
        i
      );

      daysInMonth.push({
        date: nextMonthDate.getDate(),
        day: daysOfWeek[nextMonthDate.getDay()],
        month: nextMonthDate.getMonth() + 1,
        isCurrentMonth: false,
        fullDate: nextMonthDate,
      });
    }

    setMonthDays(daysInMonth);
    if (daysInMonth.length > 34) {
      setNewLoad(!newLoad);
    }
  };

  useEffect(() => {
    changePageTitle("Monthly Time Logs")
    generateMonthDays();
  }, [currentMonth]);

  useEffect(() => {
    if (monthDays.length > 34) {
      getMonthData();
    }
  }, [newLoad]);
  useEffect(() => {

    changePageTitle("Monthly Time Logs");
    fetchDropDownData();
  }, []);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const monthName = MonthNames[date.getMonth()]; // Get month name from array
    const year = date.getFullYear();
    return `${day}/${monthName}/${year}`;
  };
  // Function to strip HTML tags using regular expressions
  // const stripHTMLTags = (html) => {
  //   return html.replace(/<[^>]*>?/gm, "");
  // };

  const getMonthData = async () => {
    try {
      ToggleLoader(true);
      //const dates = monthDays.map(day => new Date(currentMonth.getFullYear(), day.month - 1, day.date));
      const dates = monthDays.map((day) => {
        const year = currentMonth.getFullYear();
        const month = day.month.toString().padStart(2, "0"); // Ensure two digits
        const date = day.date.toString().padStart(2, "0"); // Ensure two digits
        return `${year}-${month}-${date}`; // Assuming you want the start of the day
      });
      let response = await ReportService.GetWorklogDetail(1, 1);
      // let response = []

      if (response.status === HTTPResponse.OK) {
        setSelectedEmployee(
         // roleId ===ROLE_TYPE.EMPLOYEE?
           [{
          value: parseInt(loggedInUserID),
          label: name,
        }]
        //:[]
      )
        setResponseArray(response.data)
        setDateHours([response.data]);

        const sortedData =
          response.data &&
          response.data.length > 0 &&
          response.data
            .map((item) => ({
              ...item,
              editedOn: new Date(item.editedOn),
            }))
            .sort((a, b) => b.editedOn - a.editedOn);

        setFilteredWorklogDetails(sortedData);


        // const responseMap = response.data.flat().reduce((map, item) => {
        //   const key = item.loggedDate.split("T")[0]; // Extract date without time
        //   const [integerPart, fractionalPart] = item.loggedHours.toString().split('.').map(Number);
        //   const newValue = (integerPart * 60) + (fractionalPart || 0); // Transform the value

        //   if (map[key]) {
        //     map[key] += newValue; // Accumulate transformed value for the same date
        //   } else {
        //     map[key] = newValue; // Initialize transformed value for the date
        //   }
        //   return map;
        // }, {});
        const responseMap = response.data.flat().reduce((map, item) => {
          const key = item.loggedDate.split("T")[0]; // Extract date without time
          const [integerPart, fractionalPart] = item.loggedHours.toString().split('.');

          // Convert the integer part to minutes and pad the fractional part
          const newValue = (parseInt(integerPart) * 60) + parseInt((fractionalPart || '00').padEnd(2, '0'));

          if (map[key]) {
            map[key] += newValue;
          } else {
            map[key] = newValue;
          }
          return map;
        }, {});

        const calculateCurrentMonthTotal = (data, currentMonth) => {

          const currentMonthStr = (currentMonth.getMonth() + 1).toString().padStart(2, "0");
          let total = 0;

          for (const key in data) {
            const entryDate = new Date(key);
            const entryMonthStr = (entryDate.getMonth() + 1).toString().padStart(2, "0");
            if (entryMonthStr === currentMonthStr) {
              total += data[key];
            }
          }

          return total.toFixed(2).replace(/\.?0+$/, "");
        };

        // Usage
        const currentMonthTotal = calculateCurrentMonthTotal(responseMap, currentMonth);
        //  setCurrentMonthTotal(currentMonthTotal);
        // Update totalHours in monthDays based on matching dates

        const updatedMonthDays = monthDays.map((day) => {
          const dateKey = `${currentMonth.getFullYear()}-${String(
            day.month
          ).padStart(2, "0")}-${String(day.date).padStart(2, "0")}`;
          const totalHours = responseMap[dateKey] || 0; // Use totalHours from response if available, otherwise default to 0
          return { ...day, totalHours };
        });
        setMonthDays(updatedMonthDays);
        // console.log("",updatedMonthDays.filter((a)=>a.isCurrentMonth).map(a)=>a));


        const totalCurrentMonthHours = updatedMonthDays && updatedMonthDays.reduce((acc, record) => {
          if (record.isCurrentMonth) {
            return acc + record.totalHours;
          }
          return acc;
        }, 0);
        const totalCurrentMonthHoursMin = convertMinutesToHours(totalCurrentMonthHours);

        setCurrentMonthTotal(totalCurrentMonthHoursMin)

        ToggleLoader(false);

        const selectedProjectIds = selectedProject && selectedProject.map(proj => proj.value);
        const selectedEmployeeIds = selectedEmployee && selectedEmployee.map(emp => emp.value);
        if (selectedProjectIds.length > 0 || selectedEmployeeIds.length > 0) {
          filterDayTotal();
        }
      } else {
        if (response.status === HTTPResponse.Unauthorized) {
          LogOut();
        }
        ToggleLoader(false);
      }
    } catch (error) {
      ToggleLoader(false);
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };
  const TaskIdLinkRenderer = ({ value, taskTitle, isFromDeletedProject }) => {
    return value ? <a className={isFromDeletedProject == true ? "disabled" : ""} target="_blank"
      rel="noreferrer" href={"/EditActionItem/" + taskTitle}>{value}</a> : null;
  };
  const EllipsisCellRenderers = ({ value }) => {
    const maxLength = 65;
    const displayValue = value && value.length > maxLength ? value.slice(0, maxLength) + '...' : value;

    return <span>{displayValue}</span>;
};
const EllipsisCellRendererTaskName = ({ value }) => {
  const maxLength = 58;
  const displayValue = value && value.length > maxLength ? value.slice(0, maxLength) + '...' : value;

  return <span>{displayValue}</span>;
};
// const EllipsisCellRenderers = ({ value }) => {
//   const maxLength = 65;

//   // Ensure the text is truncated at the end of the last full word within the maxLength
//   const truncatedValue =
//     value && value.length > maxLength
//       ? value.slice(0, value.slice(0, maxLength).lastIndexOf(' ')) + '...'
//       : value;

//   return <span style={{ whiteSpace: 'nowrap' }}>{truncatedValue}</span>;
// };

  const columnDefs = [
    {
      headerName: "Employee Name",
      field: "employeeName",
      flex: 1,
      filter: false,
      sortable: false,
      tooltipField: "employeeName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },

      headerClass: "header",
    },
    {
      headerName: "Date",
      field: "date",
      width: 130,
      filter: false,
      sortable: false,
      tooltipField: "date",
      headerClass: "header",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Project",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      field: "project",
      width: 140,
      filter: false,
      sortable: false,
      tooltipField: "project",
      headerClass: "header",
    },

    {
      headerName: "Work Done",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",

      },
      field: "workDone",
      width: 140,
      filter: false,
      sortable: false,
      tooltipField: "workDone",
      headerClass: "header",
    },
    {
      headerName: "Task ID",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      field: "taskId",
      width: 125,
      filter: false,
      sortable: false,
      tooltipField: "taskId",
      cellRenderer: (params) => (
        <div>
          <TaskIdLinkRenderer value={params.data.taskId} projectID={params.data.taskId} taskTitle={params.data.taskId} isFromDeletedProject={params.data.isFromDeletedProject} />
        </div>

      ),
      headerClass: "header",
    },
    {
      headerName: "Task Name",
      cellRendererFramework: EllipsisCellRendererTaskName,
 
      cellStyle: {
        textOverflow: "ellipsis",
                    whiteSpace: "normal !important",
                    paddingTop: "5px",
                    overflow: "hidden",
                    lineHeight: 1.2,
    
    },
      field: "taskName",
      width: 260,
      filter: false,
      sortable: false,
      tooltipField: "taskName",
      headerClass: "header",
    },
    {
      headerName: "Comment",
      cellRendererFramework: EllipsisCellRenderers,

      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "normal !important",
        paddingTop: "5px",
        overflow: "hidden",
        lineHeight: 1.2,
      },
      field: "comment",
      flex: 1,
      filter: false,
      sortable: false,
      tooltipField: "comment",
      headerClass: "header",
    },


    {
      headerName: "Hrs:Mins",
      field: "hours",
      width: 110,
      filter: false,
      sortable: false,
      //tooltipField: "hours",

      headerClass: "header",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      valueFormatter: (params) => formatCorrectHours(params.value)
    },



    // {
    //   headerName: "Issue Type",
    //   width:120,
    //   cellStyle: {
    //     textOverflow: "ellipsis",
    //     whiteSpace: "pre",
    //     overflow: "hidden",
    // },
    //   field: "miscName",

    //   filter: false,
    //   sortable: false,
    //   tooltipField: "miscName",
    //   headerClass: "header",
    // },
    // {
    //   headerName: "Issue Sub Type",
    //   width:128,
    //   cellStyle: {
    //     textOverflow: "ellipsis",
    //     whiteSpace: "pre",
    //     overflow: "hidden",
    // },
    //   field: "key",

    //   filter: false,
    //   sortable: false,
    //   tooltipField: "key",
    //   headerClass: "header",
    // },


  ];
  const onGridReady = useCallback((params) => { }, []);
  const onSortChanged = (e) => {
    e.api.refreshCells();
  };

  const HandleDropdownChange = (selectedOptions, id, drp) => {
    if (drp === "employeeId") {
      setSelectedEmployee(selectedOptions);
    } else {
      setSelectedProject(selectedOptions);
    }
  };
  const defaultColDef = {
    sortable: true,
    flex: 1,
    cellClass: "td",
  };

  const gridOptions = {
    columnDefs: columnDefs,
    defaultColDef,
    rowHeight: DefaultAGGridProps.ROW_HEIGHT,
  };
  const [gridData, setGridData] = useState([]);
  const [gridOpen, setGridOpen] = useState(false);
  const handleDateClicked = async (fullDate, weekIndex, dayIndex) => {

    setWeekDays([]);
    setClickedWeekIndex(weekIndex); // Set the clicked week index
    setClickedDayIndex(dayIndex); // Set the clicked day index

    const formatDateWithoutTimeZone = (date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const date = formatDateWithoutTimeZone(fullDate);
    setSelectedDate(date);

    setGridOpen(true);

  };
  useEffect(() => {
    // Trigger filtering whenever selectedDate, selectedProject, or selectedEmployee changes
    filterGridData();
  }, [selectedDate, selectedProject, selectedEmployee, weekDays]);

  // const filterGridData = () => {
  //   ToggleLoader(true)
  //   let filteredData = filteredWorklogDetails;


  //   if (
  //     selectedDate &&
  //     selectedProject.value > 0 &&
  //     selectedEmployee.value > 0
  //   ) {
  //     filteredData = filteredData.filter(
  //       (item) =>
  //         item.loggedDate &&
  //         item.loggedDate.split("T")[0] === selectedDate &&
  //         item.projectID === selectedProject.value &&
  //         item.loggedByEmployee === selectedEmployee.label
  //     );
  //   } else if (selectedDate && selectedProject.value > 0) {
  //     filteredData = filteredData.filter(
  //       (item) =>
  //         item.loggedDate &&
  //         item.loggedDate.split("T")[0] === selectedDate &&
  //         item.projectID === selectedProject.value
  //     );
  //   } else if (selectedDate && selectedEmployee.value > 0) {
  //     filteredData = filteredData.filter(
  //       (item) =>
  //         item.loggedDate &&
  //         item.loggedDate.split("T")[0] === selectedDate &&
  //         item.loggedByEmployee === selectedEmployee.label
  //     );
  //   } else if (selectedDate) {
  //     filteredData = filteredData.filter(
  //       (item) =>
  //         item.loggedDate && item.loggedDate.split("T")[0] === selectedDate
  //     );
  //   }


  //   filteredData = filteredData.map((item) => ({
  //     employeeName: item.loggedByEmployee,
  //     date: item.loggedDate ? formatDate(item.loggedDate) : "",
  //     loggedDate: item.loggedDate ? formatDate(item.loggedDate) : "",
  //     editedOn: item.editedOn ? item.editedOn : new Date(),
  //     hours: item.loggedHours || 0,
  //     project: item.projectName || "",
  //     taskId: item.key || "",
  //     taskName: item.title || "",
  //     loggedBy: item.loggedBy,
  //     projectID: item.projectID,
  //     comment: stripHTMLTags(item.comments),
  //     miscellaneousTimeLogId: item.miscellaneousTimeLogId,
  //     misctaskType: item.misctaskType,
  //     miscCategory: item.miscCategory,
  //     miscMisctaskName: item.miscMisctaskName,
  //     workLogDetailID: item.workLogDetailID,
  //     miscName: item.miscName,
  //     workLogTaskID: item.taskID,
  //     loggedDate: item.loggedOn ? item.loggedOn : new Date(),
  //     originalEstimate: item.originalEstimate,
  //     actionItemId: item.taskID || 0,
  //   }));

  //   setGridData(filteredData);
  //   ToggleLoader(false)
  // };
  const filterGridData = () => {
    ToggleLoader(true);
    let filteredData = filteredWorklogDetails;

    const selectedProjectIds = selectedProject.map(proj => proj.value);
    const selectedEmployeeIds = selectedEmployee.map(emp => emp.value);


    if (
      selectedDate &&
      selectedProjectIds.length > 0 &&
      selectedEmployeeIds.length > 0 &&
      weekDays.length === 0
    ) {
      filteredData = filteredData.filter(
        (item) =>
          item.loggedDate &&
          item.loggedDate.split("T")[0] === selectedDate &&
          selectedProjectIds.includes(item.projectID) &&
          selectedEmployeeIds.includes(item.loggedBy)
        //&&
        //item.loggedByEmployee === selectedEmployee.label
      );
    }

    else if (
      !selectedDate &&
      selectedProjectIds.length > 0 &&
      selectedEmployeeIds.length > 0 &&
      weekDays.length > 0
    ) {
      filteredData = filteredData.filter(
        (item) =>
          item.loggedDate &&
          weekDays.includes(item.loggedDate.split("T")[0]) &&
          selectedProjectIds.includes(item.projectID) &&
          selectedEmployeeIds.includes(item.loggedBy)
        //&&
        //item.loggedByEmployee === selectedEmployee.label
      );
    }
    //////////////////
    else if (weekDays.length > 0 && selectedProjectIds.length > 0) {
      filteredData = filteredData.filter(
        (item) =>
          item.loggedDate &&
          weekDays.includes(item.loggedDate.split("T")[0]) &&
          selectedProjectIds.includes(item.projectID)
      );
    } else if (weekDays.length > 0 && selectedEmployeeIds.length > 0) {
      filteredData = filteredData.filter(
        (item) =>
          item.loggedDate &&
          weekDays.includes(item.loggedDate.split("T")[0]) &&
          selectedEmployeeIds.includes(item.loggedBy)
        //item.loggedByEmployee === selectedEmployee.label
      );
    } else if (weekDays.length > 0) {
      filteredData = filteredData.filter(
        (item) =>
          weekDays.includes(item.loggedDate.split("T")[0])
      );
    }
    ///////////////////


    else if (selectedDate && selectedProjectIds.length > 0) {
      filteredData = filteredData.filter(
        (item) =>
          item.loggedDate &&
          item.loggedDate.split("T")[0] === selectedDate &&
          selectedProjectIds.includes(item.projectID)
      );
    } else if (selectedDate && selectedEmployeeIds.length > 0) {
      filteredData = filteredData.filter(
        (item) =>
          item.loggedDate &&
          item.loggedDate.split("T")[0] === selectedDate &&
          selectedEmployeeIds.includes(item.loggedBy)
        //item.loggedByEmployee === selectedEmployee.label
      );
    } else if (selectedDate) {
      filteredData = filteredData.filter(
        (item) =>
          item.loggedDate && item.loggedDate.split("T")[0] === selectedDate
      );
    }

    filteredData = filteredData.map((item) => ({
      employeeName: item.loggedByEmployee,
      date: item.loggedDate ? formatDate(item.loggedDate.split("T")[0]) : "",
      loggedDate: item.loggedDate ? formatDate(item.loggedDate) : "",
      editedOn: item.editedOn ? item.editedOn : new Date(),
      hours: item.loggedHours || 0,
      project: item.projectKey || "",
      taskId: item.key || "",
      taskName: item.title || "",
      loggedBy: item.loggedBy,
      projectID: item.projectID,
      comment: stripHTMLTags(item.comments),
      miscellaneousTimeLogId: item.miscellaneousTimeLogId,
      misctaskType: item.misctaskType,
      miscCategory: item.miscCategory,
      miscMisctaskName: item.miscMisctaskName,
      workLogDetailID: item.workLogDetailID,
      miscName: item.miscName,
      workLogTaskID: item.taskID,
      loggedDate: item.loggedOn ? item.loggedOn : new Date(),
      originalEstimate: item.originalEstimate,
      actionItemId: item.taskID || 0,
      isFromDeletedProject: item.isDeletedProject,
      workDone: item.workDone || ""
    }));

    setGridData(filteredData);
    ToggleLoader(false);
  };
  const [active, setActive] = useState(false);
  const CheckboxOption = (props) => (
    <components.Option {...props}>
      <input type="checkbox" checked={props.isSelected} onChange={() => null} />
      {" "} {props.label}
    </components.Option>
  );
  const handleWeekTotalClicked = (weekDays, weekIndex) => {
    setSelectedDate("");
    const formatDateWithoutTimeZone = (date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const formattedDates = weekDays.map((day) =>
      formatDateWithoutTimeZone(new Date(day.fullDate))
    );
    setWeekDays(formattedDates);
    setSelectedDate(""); // Clear selectedDate if needed
    setClickedWeekIndex(weekIndex); // Set the clicked week index
    setClickedDayIndex(null); // Clear the clicked day index
    setGridOpen(true); // Open the grid
    setWeekDays(formattedDates)
  };

  const convertMinutesToHours = (minutes) => {

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}.${remainingMinutes.toString().padStart(2, '0')}`;
  };

  const filterDayTotal = () => {

    // if(responseArray && responseArray.length>0){
    //  const selectedProjectIds = selectedProject.map(proj => proj.value);
    //   const responseMap = responseArray.flat().reduce((map, item) => {
    //     const key = item.loggedDate.split("T")[0];
    //     const [integerPart, fractionalPart] = item.loggedHours.toString().split('.').map(Number);
    //     const newValue = (integerPart * 60) + (fractionalPart || 0);

    //     if (map[key]) {
    //       map[key].totalHours += newValue;
    //     } else {
    //       map[key] = { totalHours: newValue, projectId:   selectedProjectIds.includes(item.projectID) };
    //     }
    //     return map;
    //   }, {});

    //   const calculateCurrentMonthTotal = (data, currentMonth) => {
    //     const currentMonthStr = (currentMonth.getMonth() + 1).toString().padStart(2, "0");
    //     let total = 0;

    //     for (const key in data) {
    //       const entryDate = new Date(key);
    //       const entryMonthStr = (entryDate.getMonth() + 1).toString().padStart(2, "0");
    //       if (entryMonthStr === currentMonthStr) {
    //         total += data[key].totalHours;
    //       }
    //     }

    //     return total.toFixed(2).replace(/\.?0+$/, "");
    //   };

    //   const currentMonthTotal = calculateCurrentMonthTotal(responseMap, currentMonth);
    //   setCurrentMonthTotal(currentMonthTotal);

    //   const updatedMonthDays = monthDays.map((day) => {
    //     const dateKey = `${currentMonth.getFullYear()}-${String(day.month).padStart(2, "0")}-${String(day.date).padStart(2, "0")}`;
    //     const totalHours = responseMap[dateKey] &&  selectedProjectIds.includes(responseMap[dateKey].projectId)  ? responseMap[dateKey].totalHours : 0;
    //     return { ...day, totalHours };
    //   });

    //   setMonthDays(updatedMonthDays);
    // }
    // if (responseArray && responseArray.length > 0) {
    //   const selectedProjectIds = selectedProject.map(proj => proj.value);

    //   const responseMap = responseArray.flat().reduce((map, item) => {
    //     const key = item.loggedDate.split("T")[0];
    //     const [integerPart, fractionalPart] = item.loggedHours.toString().split('.').map(Number);
    //     const newValue = (integerPart * 60) + (fractionalPart || 0);

    //     if (map[key]) {
    //       if (selectedProjectIds.includes(item.projectID)) {
    //         map[key].totalHours += newValue;
    //       }
    //     } else {
    //       map[key] = {
    //         totalHours: selectedProjectIds.includes(item.projectID) ? newValue : 0,
    //         projectId: item.projectID
    //       };
    //     }
    //     return map;
    //   }, {});

    //   const calculateCurrentMonthTotal = (data, currentMonth) => {
    //     const currentMonthStr = (currentMonth.getMonth() + 1).toString().padStart(2, "0");
    //     let total = 0;

    //     for (const key in data) {
    //       const entryDate = new Date(key);
    //       const entryMonthStr = (entryDate.getMonth() + 1).toString().padStart(2, "0");
    //       if (entryMonthStr === currentMonthStr) {
    //         total += data[key].totalHours;
    //       }
    //     }

    //     return total.toFixed(2).replace(/\.?0+$/, "");
    //   };

    //   const currentMonthTotal = calculateCurrentMonthTotal(responseMap, currentMonth);
    //   setCurrentMonthTotal(currentMonthTotal);

    //   const updatedMonthDays = monthDays.map((day) => {
    //     const dateKey = `${currentMonth.getFullYear()}-${String(day.month).padStart(2, "0")}-${String(day.date).padStart(2, "0")}`;
    //     const totalHours = responseMap[dateKey] && selectedProjectIds.includes(responseMap[dateKey].projectId) ? responseMap[dateKey].totalHours : 0;
    //     return { ...day, totalHours };
    //   });

    //   setMonthDays(updatedMonthDays);
    // }
    if (responseArray && responseArray.length > 0) {
      const selectedProjectIds = selectedProject.map(proj => proj.value);
      const selectedEmployeeIds = selectedEmployee.map(emp => emp.value);
      //   const responseMap = responseArray.flat().reduce((map, item) => {
      //     const key = item.loggedDate.split("T")[0];
      //     const [integerPart, fractionalPart] = item.loggedHours.toString().split('.').map(Number);
      //     const newValue = (integerPart * 60) + (fractionalPart || 0);


      //   if (selectedProjectIds.length === 0 || selectedProjectIds.includes(item.projectID)) {
      //     if (map[key]) {
      //       map[key].totalHours += newValue;
      //     } else {
      //       map[key] = {
      //         totalHours: newValue,
      //         projectId: item.projectID
      //       };
      //     }
      //   }
      //   else if (selectedEmployeeIds.length === 0 || selectedEmployeeIds.includes(item.loggedBy)) {
      //     if (map[key]) {
      //       map[key].totalHours += newValue;
      //     } else {
      //       map[key] = {
      //         totalHours: newValue,
      //         //projectId: item.projectID
      //         employeeId:item.loggedBy
      //       };
      //     }
      //   }
      //   return map;
      // }, {});
      // const responseMap = responseArray.flat().reduce((map, item) => {
      //   const key = item.loggedDate.split("T")[0];
      //   const [integerPart, fractionalPart] = item.loggedHours.toString().split('.').map(Number);
      //   const newValue = (integerPart * 60) + (fractionalPart || 0);

      //   const projectFilter = selectedProjectIds.length === 0 || selectedProjectIds.includes(item.projectID);
      //   const employeeFilter = selectedEmployeeIds.length === 0 || selectedEmployeeIds.includes(item.loggedBy);

      //   if (projectFilter && employeeFilter) {
      //     if (map[key]) {
      //       map[key].totalHours += newValue;
      //     } else {
      //       map[key] = {
      //         totalHours: newValue,
      //         projectId: item.projectID,
      //         employeeId: item.loggedBy
      //       };
      //     }
      //   }
      //   return map;
      // }, {});
      const responseMap = responseArray.flat().reduce((map, item) => {
        const key = item.loggedDate.split("T")[0];
        const [integerPart, fractionalPart] = item.loggedHours.toString().split('.');

        // Convert the integer part to minutes and pad the fractional part
        const newValue = (parseInt(integerPart) * 60) + parseInt((fractionalPart || '00').padEnd(2, '0'));

        const projectFilter = selectedProjectIds.length === 0 || selectedProjectIds.includes(item.projectID);
        const employeeFilter = selectedEmployeeIds.length === 0 || selectedEmployeeIds.includes(item.loggedBy);

        if (projectFilter && employeeFilter) {
          if (map[key]) {
            map[key].totalHours += newValue;
          } else {
            map[key] = {
              totalHours: newValue,
              projectId: item.projectID,
              employeeId: item.loggedBy
            };
          }
        }
        return map;
      }, {});
      const calculateCurrentMonthTotal = (data, currentMonth) => {
        const currentMonthStr = (currentMonth.getMonth() + 1).toString().padStart(2, "0");
        let total = 0;

        for (const key in data) {
          const entryDate = new Date(key);
          const entryMonthStr = (entryDate.getMonth() + 1).toString().padStart(2, "0");
          if (entryMonthStr === currentMonthStr) {
            total += data[key].totalHours;
          }
        }

        return total.toFixed(2).replace(/\.?0+$/, "");
      };

      const currentMonthTotal = calculateCurrentMonthTotal(responseMap, currentMonth);
      //setCurrentMonthTotal(currentMonthTotal);

      const updatedMonthDays = monthDays.map((day) => {
        const dateKey = `${currentMonth.getFullYear()}-${String(day.month).padStart(2, "0")}-${String(day.date).padStart(2, "0")}`;
        const totalHours = responseMap[dateKey] ? responseMap[dateKey].totalHours : 0;
        return { ...day, totalHours };
      });
      setMonthDays(updatedMonthDays);
      const totalCurrentMonthHours = updatedMonthDays && updatedMonthDays.reduce((acc, record) => {
        if (record.isCurrentMonth) {
          return acc + record.totalHours;
        }
        return acc;
      }, 0);
      const totalCurrentMonthHoursMin = convertMinutesToHours(totalCurrentMonthHours);

      setCurrentMonthTotal(totalCurrentMonthHoursMin)
    }
  }
  useEffect(() => {
    filterDayTotal();
  }, [selectedProject, selectedEmployee]);

  return (
    <>
      {/* <title>Timetracker</title> */}

      <section className="breadcrumb-block">
        <BreadcrumbComponent /></section>

      <section className="time-tracker-body">
        <div className="time-tracker" style={{ position: 'relative' }}>
          <div className="row">
            <div className="col-12 col-md-4 col-lg-3">
              <label>Project</label>
              <Select
                name="projectList"
                placeholder="Please Select"
                value={selectedProject}

                options={[
                  ///{ value: 0, label: "All" },
                  ...projects.map((project) => ({
                    value: project.value,
                    label: project.label,
                  })),
                ]}
                onChange={(selectedOptions) => {
                  setSelectedProject(selectedOptions);
                  HandleDropdownChange(
                    selectedOptions,
                    "projects",
                    "projectId"
                  );
                }}
                components={{ Option: CheckboxOption }} // Use custom Option component
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                isMulti

              />
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <label>Employee</label>
              <Select
                inputId="employeeDropDown"
                placeholder="Please Select"
                value={selectedEmployee}
                options={[
                  //{ value: 0, label: "All" }, // Option to select all projects
                  ...employees.map((emp) => ({
                    value: emp.value,
                    label:
                      //`${
                      emp.label,
                    //} ${userId === emp.value ? "(ME)" : ""}`,
                  })),
                ]}
                // options={employees.map((emp) => ({
                //   value: emp.value,
                //   label: emp.label,
                // }))}
                onChange={(selectedOptions) => {
                  setSelectedEmployee(selectedOptions);
                  HandleDropdownChange(
                    selectedOptions,
                    "employees",
                    "employeeId"
                  );
                }}
                components={{ Option: CheckboxOption }} // Use custom Option component
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                isMulti
              />
            </div>


          </div>
          <div className="floating-btn-timetrack">

            <button className="addtimelog" style={{ width: "105px", padding: '5px 8px' }} onClick={() => navigate("/AddTimeLogging")}>
              Add Time Log
            </button>

            <img
              src="../Assets/images/refresh.png"
              //width={20}
              onClick={() => getMonthData()}
              style={{
                cursor: "pointer",
                width: "25px",
                marginBottom :"5px"

              }}
              title="Refresh"
            />

          </div>
          <div className="time-track-main mt-4">
            <div className="switch-month-hours-fbox">
              <div className="switch-month-sec">
                <div className="previous-switch">
                  <button onClick={goToPreviousMonth}>
                    <i className="fa fa-caret-left" aria-hidden="true"></i>
                  </button>
                </div>
                <div className="month-sec">
                  <span>
                    {currentMonth.toLocaleString("default", { month: "long" })},{" "}
                    {currentMonth.getFullYear()}
                  </span>
                </div>
                <div className="next-switch">
                  <button onClick={goToNextMonth}>
                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="current-month-hours-sec">
                <span className="current-month-txt">Current Month</span>
                <span className="month-hours">{currentMonthTotal}</span>
              </div>
            </div>

            <div className="timetracker-grid-sec">
              <table className="tab-width">
                <thead>
                  <tr>
                    {[
                      "Monday",
                      "Tuesday",
                      "Wednesday",
                      "Thursday",
                      "Friday",
                      "Saturday",
                      "Sunday",
                      "Week Total",
                    ].map((day) => (
                      <th key={day}>{day}</th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {Array.from(
                    { length: Math.ceil(monthDays.length / 7) },
                    (week, weekIndex) => {
                      const weekStartIndex = weekIndex * 7;
                      const weekEndIndex = Math.min(
                        weekStartIndex + 7,
                        monthDays.length
                      );
                      const weekDays = monthDays.slice(weekStartIndex, weekEndIndex);
                      const weekTotalHours = weekDays.reduce(
                        (total, day) => total + day.totalHours,
                        0
                      );
                      { console.log("wwwwww", weekTotalHours) }
                      return (
                        <>
                          <tr key={weekIndex}>
                            {weekDays.map((day, index) => (
                              <td style={{ cursor: 'pointer' }}
                                key={index}
                                // className={
                                //   day.isCurrentMonth
                                //     ? ""
                                //     : "previous-next-month-date"
                                // }
                                className={`${day.isCurrentMonth ? "" : ""} ${clickedWeekIndex === weekIndex && clickedDayIndex === index ? "selected-date" : ""}`}


                                onClick={() => {
                                  handleDateClicked(day.fullDate, weekIndex, index);
                                  setActive(day.date);
                                }}
                              >
                                {/* {(day.totalHours).toFixed(2).replace(/\.?0+$/, '')} */}

                                {day.totalHours ? convertMinutesToHours((day.totalHours)) : 0}

                                {/* {day.totalHours?  (day.totalHours) :0} */}
                                {/* {day.totalHours?   Number(day.totalHours).toFixed(2).replace(/\.?0+$/, ''):0} */}
                                <span className="date-track">{day.date}</span>
                              </td>
                            ))}
                            <td className="previous-next-month-date"
                              //style={{fontWeight:500}}
                              //onClick={handleWeekTotalClicked(weekDays)}>
                              onClick={() => handleWeekTotalClicked(weekDays, weekIndex)}>
                              {/* {weekTotalHours} */}
                              {/* {weekTotalHours? Number(weekTotalHours).toFixed(2).replace(/\.?0+$/, ''):0} */}
                              {weekTotalHours ? WeeklyFormatCorrectHours(weekTotalHours) : 0}

                            </td>
                          </tr>
                          {/* {gridOpen && selectedDate !== "" && clickedWeekIndex === weekIndex && ( */}
                          {gridOpen && clickedWeekIndex === weekIndex && (

                            <tr className="timetracker-sub-grid-sec sub-table aggridtracker">
                              <td colSpan="8" style={{ backgroundColor: '#CAC0DC' }}>
                                <AGGrid
                                  isTimeTracker={true}
                                  rowData={gridData}
                                  colDefs={columnDefs}
                                  gridOptions={gridOptions}
                                  onGridReady={onGridReady}
                                  pagination="6"
                                  onSortChanged={(e) => onSortChanged(e)}
                                />
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
            {/* <div className="timetracker-sub-grid-sec ">
            {gridOpen && selectedDate !== "" && (
            <AGGrid
              isTimeTracker={true}
              rowData={gridData}
              colDefs={columnDefs}
              gridOptions={gridOptions}
              onGridReady={onGridReady}
              pagination="6"
              onSortChanged={(e) => onSortChanged(e)}
            />
          )}</div> */}
          </div>


        </div>
      </section>
    </>
  );
};

export default TimeTracker;
