import { useState } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";

import {
    ClassicEditor,
    Essentials,
    Bold,
    Code,
    Italic,
    Strikethrough,
    Subscript,
    Superscript,
    Underline,
    Paragraph,
    Undo,
    Image,
    ImageInsert,
    ImageResizeEditing,
    ImageResizeHandles,
    Alignment,
    Indent,
    IndentBlock,
    List,
    ImageStyle,
    ImageToolbar,
    LinkImage,
    ImageCaption,
    ImageResize,
    Heading,
    Font,
    FontFamily,
    HorizontalLine,
    PageBreak,
    ShowBlocks,
    AutoLink,
    Link,
    BlockQuote,
    SpecialCharactersEssentials,
    SpecialCharacters,
    TableToolbar,
    Table,
    MediaEmbed,
    TableCaption,
    TableCellProperties,
    TableProperties,
    WordCount,
} from "ckeditor5";

import "ckeditor5/ckeditor5.css";
import { AddLocalStorageData } from "../Helpers/Utilities";

const undo = {
    plugins: [Undo],
    toolbar: ["undo", "redo"],
};

const basicTextStyles = {
    plugins: [
        Bold,
        Italic,
        Underline,
        Strikethrough,
        Code,
        Subscript,
        Superscript,
        BlockQuote,
    ],

    toolbar: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "code",
        "subscript",
        "superscript",
        "blockQuote",
    ],
};

const blockIndentation = {
    plugins: [Indent, IndentBlock],
    toolbar: ["outdent", "indent"],
};

const list = {
    plugins: [List],
    toolbar: ["bulletedList", "numberedList"],
};

const hyperlink = {
    plugins: [Link, AutoLink],
    toolbar: ["link"],
};

const image = {
    plugins: [
        Image,
        ImageCaption,
        ImageResize,
        ImageStyle,
        ImageToolbar,
        LinkImage,
        ImageInsert,
        ImageResizeEditing,
        ImageResizeHandles,
    ],
    toolbar: ["insertImage"],
};

const table = {
    plugins: [
        Table,
        TableToolbar,
        TableCaption,
        TableProperties,
        TableCellProperties,
    ],
    toolbar: ["insertTable"],
    contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "toggleTableCaption",
        "tableProperties",
        "tableCellProperties",
    ],
};

const media = {
    plugins: [MediaEmbed],
    toolbar: ["mediaEmbed"],
};

const heading = {
    plugins: [Heading],
    toolbar: ["heading"],
};

const font = {
    plugins: [Font, FontFamily],
    toolbar: ["fontSize", "fontFamily", "fontColor", "fontBackgroundColor"],
};

const specialCharacters = {
    plugins: [SpecialCharacters, SpecialCharactersEssentials],
    toolbar: ["specialCharacters"],
};

const others = {
    plugins: [HorizontalLine, PageBreak, ShowBlocks, WordCount],
    toolbar: ["horizontalLine", "pageBreak", "showBlocks"],
};

const RichTextEditor = ({
    id,
    value = "",
    onChange = () => { },
    onFocus = () => { },
    onBlur = () => { },
    showCount = false,
    isEditActionItem,handleCancelEditor,handleSaveEditor,editModeDesc,editable,changeDescState
}) => {
    const [editorStats, setEditorStats] = useState({
        characters: 0,
        words: 0,
    });
//const [editable,setEditable]=useState(false);
const handleCancel=()=>{
   //setEditable(false);
    handleCancelEditor();
}
const handleSave=()=>{
   // setEditable(false);
    handleSaveEditor();
}
    return (
        <>
        <div 
        onClick={()=>{
            //editModeDesc(); 
            changeDescState();
            //AddLocalStorageData("DescValue",value) ;
            }}
         className="editor-parent"
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.4rem",
                justifyContent: "space-between",
                position: "relative"
            }}
        >
         {editable===false && isEditActionItem && (   <div  onClick={()=>{editModeDesc(); changeDescState();
        //AddLocalStorageData("DescValue",value) ;
         }}  className="editor-icon" style={{alignContent:'center',cursor:'pointer'}}><img
           
                                                                    width="12px"
                                                                    src="../Assets/images/Edit_icon.png"
                                                                    alt="Edit"
                                                                   // onClick={() => setIsTitleEditable(true)}
                                                                  //onClick={handleEditClick}
                                                                    title="Edit"
                                                                   
                                                                /></div>)}
            

            <CKEditor
                id={id}
              disabled={isEditActionItem && !editable }
                editor={ClassicEditor}
                config={{
                    toolbar: {
                        items: [
                           ...undo.toolbar,
                            "|",
                            ...basicTextStyles.toolbar,
                            "|",
                            ...heading.toolbar,
                            "|",
                            ...list.toolbar,
                            "|",
                            ...blockIndentation.toolbar,
                            "|",
                            "alignment",
                            "|",
                            ...hyperlink.toolbar,
                            ...image.toolbar,
                            ...media.toolbar,
                            ...table.toolbar,
                            "-",
                            ...font.toolbar,
                            "|",
                            ...specialCharacters.toolbar,
                            ...others.toolbar,
                        ],
                        shouldNotGroupWhenFull: true,
                    },
                    plugins: [
                        Essentials,
                        ...undo.plugins,
                        ...basicTextStyles.plugins,
                        ...heading.plugins,
                        ...list.plugins,
                        ...blockIndentation.plugins,
                        Paragraph,
                        Alignment,
                        ...hyperlink.plugins,
                        ...image.plugins,
                        ...media.plugins,
                        ...table.plugins,
                        ...font.plugins,
                        ...specialCharacters.plugins,
                        ...others.plugins,
                    ],
                    image: {
                        toolbar: [
                            {
                                name: "imageStyle:icons",
                                title: "Alignment",
                                items: [
                                    "imageStyle:inline",
                                    "imageStyle:alignLeft",
                                    "imageStyle:alignRight",
                                ],
                                defaultItem: "imageStyle:inline",
                            },
                            "|",
                            {
                                name: "imageStyle:pictures",
                                title: "Style",
                                items: [
                                    "imageStyle:alignBlockLeft",
                                    "imageStyle:block",
                                    "imageStyle:alignBlockRight",
                                ],
                                defaultItem: "imageStyle:block",
                            },
                            "|",
                            "toggleImageCaption",
                            "imageTextAlternative",
                            "linkImage",
                        ],
                        insert: {
                            integrations: ["url"],
                            type: "inline",
                        },
                    },
                    table: {
                        contentToolbar: table.contentToolbar,
                        defaultHeadings: { rows: 1, columns: 1 },
                    },
                    
                   heading: {
                        options: [
                            {
                                model: "paragraph",
                                title: "Paragraph",
                                class: "ck-heading_paragraph",
                            },
                            {
                                model: "heading1",
                                view: "h1",
                                title: "Heading 1",
                                class: "ck-heading_heading1",
                            },
                            {
                                model: "heading2",
                                view: "h2",
                                title: "Heading 2",
                                class: "ck-heading_heading2",
                            },
                            {
                                model: "heading3",
                                view: "h3",
                                title: "Heading 3",
                                class: "ck-heading_heading3",
                            }, {
                                model: "heading4",
                                view: "h4",
                                title: "Heading 4",
                                class: "ck-heading_heading4",
                            },
                            {
                                model: "heading5",
                                view: "h5",
                                title: "Heading 5",
                                class: "ck-heading_heading5",
                            },
                            {
                                model: "heading6",
                                view: "h6",
                                title: "Heading 6",
                                class: "ck-heading_heading6",
                            },
                        ],
                    },
                    fontFamily: {
                        supportAllValues: true,
                    },
                    fontSize: {
                        options: [
                            "default",
                            8,
                            9,
                            10,
                            11,
                            12,
                            14,
                            16,
                            18,
                            20,
                            22,
                            24,
                            26,
                            28,
                            36,
                            48,
                            72,
                        ],
                        supportAllValues: true,
                    },
                    wordCount: {
                        onUpdate: (stats) => {
                            setEditorStats({
                                characters: stats.characters,
                                words: stats.words,
                            });
                        },
                    },
                }}
                data={value}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
            />
            {showCount && (
                <p style={{ fontSize: "0.8rem", fontWeight: 600, margin: 0 }}>
                    Words: {editorStats.words}, Characters: {editorStats.characters}
                </p>
            )}
        </div>
    {editable && isEditActionItem && (    <div className="editor-save-cancel-sec">
        <button className="save-btn" onClick={handleSave}>Save</button>
        <button className="cancel-btn" onClick={handleCancel}>Cancel</button>
    </div>)}
    </>
    );
};

export default RichTextEditor;