
// import Select from "react-select";
// import React, { useEffect, useState } from "react";
// import { FaTimes, FaCaretDown } from 'react-icons/fa'; // Example icons from react-icons

// const CheckboxDropdown = ({
//   scroll,
//   options,
//   onChange,
//   releasesSelected,
//   selectEmployee,
//   AddStyle,
//   isFromEmployee, loading, optionsFilter, onChanges
// }) => {
//   useEffect(() => {
//     setSelectedOptions(releasesSelected || []);
//   }, [releasesSelected]);
//   const [selectedOptions, setSelectedOptions] = useState([]);
//   const [isTyping, setIsTyping] = useState(false);

//   const [search, setSearch] = useState("");
//   const handleCheckboxChange = (option) => {
//     const updatedSelection = selectedOptions.some(
//       (selected) => selected.releaseId === option.releaseId
//     )
//       ? selectedOptions.filter(
//         (selected) => selected.releaseId !== option.releaseId
//       )
//       : [...selectedOptions, option];

//     setSelectedOptions(updatedSelection);
//     onChange(updatedSelection);
//     if (optionsFilter != null) {
//       onChanges(updatedSelection)
//     }
//     loading();
//   };
//   useEffect(() => {
//     optionsFilter(selectedOptions);
//   }, [selectedOptions]);
//   const handleRemoveOption = (option) => {

//     const updatedSelection = selectedOptions.filter(
//       (selected) =>
//         selected.releaseId !== option.releaseId ||
//         selected.employeeId !== option.employeeId
//     );
//     setSelectedOptions(updatedSelection);
//     onChange(updatedSelection);
//     if (optionsFilter != null) {
//       onChanges([option])
//     };
//     loading();
//   };

//   const clearSearch = () => {
//     setSearch("");
//     setIsTyping(false);
//   };

//   const handleInputChange = (e) => {
//     setSearch(e.target.value);
//     setIsTyping(e.target.value.length > 0);
//   };

//   //const filteredOptions= options.filter((option)=>option.name.toLowerCase().includes(search.toLocaleLowerCase()));
//   const filteredOptions = options.filter((option) => option.name.toLowerCase().includes(search.toLocaleLowerCase()));
//   return (
//     <div className="d-flex  modal-bubble-wrap" style={{ alignItems: AddStyle && 'flex-start' }}>
//       <div className="" style={{ position: isFromEmployee || selectEmployee ? "relative" : "" }}>
//         <button
//           className="btn btn-secondary dropdown-toggle-split"
//           type="button"
//           id="dropdownMenuButton"
//           data-toggle="dropdown"
//           aria-haspopup="true"
//           aria-expanded="false"
//           style={{
//             color: "black",
//             float: "right",
//             backgroundColor: "transparent",
//             borderColor: "1px solid",
//             width: isFromEmployee ? "160px" : "170px",
//             height: "35px",
//             marginBottom: '5px'
//           }}
//         >
//           <span style={{ float: "left" }}>
//             {isFromEmployee ? "Assign Projects" : selectEmployee ? "Assign Employee" : "Select Releases"}
//           </span>
//           {/* {!isFromEmployee && !selectEmployee &&  */}
//           <span
//             style={{ float: "right", display: "flex", marginTop: "5px" }}
//             className="fas fa-caret-down"
//           ></span>
//           {/* } */}
//         </button>
// {/* 
//         <span
//           style={{
//             cursor: "pointer",
//             position: "absolute",
//             //left: isFromEmployee ? "150px" : selectEmployee ? "190px" : "60px",
//             zIndex: 1,
//             right: "8px",
//             top: "6px",
//             display: isFromEmployee || selectEmployee ? "" : "none"
//           }}
//           onClick={(e) => {
//             clearSearch();
//           }}
//         >
//           {isTyping ? <FaTimes /> : <FaCaretDown />}
//         </span> */}
//         <div
//           className="dropdown-menu"
//           aria-labelledby="dropdownMenuButton"
//           style={{ padding: "4px", height: "220px", overflow: "scroll", backgroundColor: "#f9f9f9", marginBottom: isFromEmployee ? "4px" : "0px", marginTop: selectEmployee ? "4px" : "0px" }}
//         >
//           <div style={{ position: !isFromEmployee && !selectEmployee && "relative" }}>
//             <input
//               type="text"
//               className="form-control"
//               placeholder="Search..."
//               value={search}
//               onChange={handleInputChange}
//               style={{ marginBottom: "5px" }}
//             />
//             <span
//               style={{
//                 cursor: "pointer",
//                 position: "absolute",
//                 right: "10px",
//                 zIndex: 1,
//                 top: "10px",
//                 //display: !isFromEmployee && !selectEmployee ? "" : "none"
//               }}
//               onClick={(e) => {
//                 e.stopPropagation();
//                 clearSearch();
//               }}
//             >
//               {isTyping && <FaTimes />}
//             </span></div>
//           {filteredOptions.map((option) => (
//             <div
//               key={option.releaseId}
//               className="form-check"
//               style={{ width: "200px" }}
//             >
//               <input
//                 type="checkbox"
//                 className="form-check-input"
//                 id={`checkbox-${option.releaseId}`}
//                 value={option.releaseId}
//                 disabled={option.isChecked ? true : null}
//                 checked={selectedOptions?.some(
//                   (selected) => selected.releaseId === option.releaseId
//                 )}
//                 onChange={() => handleCheckboxChange(option)}
//               />
//               <label
//                 className="form-check-label"
//                 htmlFor={`checkbox-${option.releaseId}`}
//               >
//                 {option.name}
//               </label>

//             </div>
//           ))}
//         </div>
//       </div>

//       {
//         selectedOptions !== undefined && selectedOptions !== null && selectedOptions.length > 0 && (
//           <div className="capsule-side-vertical-scroller">
//             <div className="ml-2 d-flex flex-wrap" style={{ marginBottom: "0px" }}>
//               {selectedOptions.map((selectedOption) => (
//                 <div
//                   key={selectedOption.releaseId}
//                   className="selectedList"
//                   style={{
//                     marginBottom: "4px",
//                     marginLeft: "4px",
//                     borderRadius: "30px",
//                     backgroundColor: "rgb(197,197,236)",
//                     padding: "2px 8px",
//                     whiteSpace: "nowrap",
//                     maxWidth: "160px",
//                     border: "2px solid rgb(197,197,236)"
//                   }}
//                   title={selectedOption.name}
//                 >
//                   <div className="selected-option d-flex align-items-center">
//                     <label style={{
//                       width: "100px",
//                       overflow: "hidden",
//                       textOverflow: "ellipsis",
//                       position: "relative",
//                       marginTop: "5px"
//                     }}>{selectedOption.name}</label>
//                     <button
//                       type="button"
//                       onClick={() => handleRemoveOption(selectedOption)}
//                     >
//                       {!selectedOption.isChecked &&
//                         <img
//                           src="Assets/images/removeIcon.png"
//                           style={{
//                             width: "19px",
//                             height: "19px",
//                             color: "#b5a5c4",
//                             borderRadius: "50%",
//                             marginLeft: "3px",
//                             marginBottom: "3px",
//                           }}
//                         />
//                       }
//                     </button>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         )
//       }


//     </div >
//   );
// };

// export default CheckboxDropdown;

import Select, { components } from "react-select";
import React, { useEffect, useState } from "react";
const CheckboxDropdown = ({
  scroll,
  options,
  onChange,
  releasesSelected,
  selectEmployee,
  AddStyle,
  isFromEmployee,
  loading,
  optionsFilter,
  onChanges
}) => {
  
  useEffect(() => {
    setSelectedOptions(releasesSelected || []);
  }, [releasesSelected]);
  useEffect(() => {
    // Automatically select options that have isChecked: true
    const preSelectedOptions = options.filter(option => option.isChecked );
    
    setSelectedOptions((prevSelected) => {
      // Combine new preSelectedOptions with existing ones, avoiding duplicates
      const newSelection = [...prevSelected, ...preSelectedOptions];
      // return Array.from(new Set(newSelection.map(opt => opt.releaseId)))
      //   .map(id => newSelection.find(opt => opt.releaseId === id));
      const uniqueSelection = Array.from(new Set(newSelection.map(opt => opt.releaseId)))
      .map(id => newSelection.find(opt => opt.releaseId === id));
    
    // Sort so that isChecked options appear first
    return uniqueSelection.sort((a, b) => b.isChecked - a.isChecked);
    });
  }, [releasesSelected, options]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectChange = (selected) => {
    const updatedSelection = selected
      ? selected.map(option => options.find(opt => opt.releaseId === option.value))
      : [];

    //setSelectedOptions(updatedSelection);
    setSelectedOptions(prevSelected => {
      // Keep the pre-selected (disabled) options intact and avoid duplicates
      const preSelectedOptions = options.filter(option => option.isChecked);
      const combinedSelection = [...updatedSelection, ...preSelectedOptions];
      return Array.from(new Set(combinedSelection.map(opt => opt.releaseId)))
        .map(id => combinedSelection.find(opt => opt.releaseId === id));
    });
    
    onChange(updatedSelection);
    if (optionsFilter != null) {
      onChanges(updatedSelection);
    }
    loading();
  };

  const handleRemoveOption = (option) => {
    const updatedSelection = selectedOptions.filter(
      (selected) => selected.releaseId !== option.value
    );
    setSelectedOptions(updatedSelection);
    onChange(updatedSelection);
    if (optionsFilter != null) {
      onChanges(updatedSelection);
    }
    loading();
  };

/* <summary>
date: 14-08-2024
Name: Mustakim
description: To allow multi and hide cross icon if lead of project
<summary>*/ 
  const CheckboxOption = (props) => (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{ marginRight: "10px" }}
        />
        <label>{props.label}</label>
      </div>
    </components.Option>
  );
  const MultiValueRemove = (props) => {
    if (props.data.isDisabled) {
      // Do not render the remove button for options with isChecked: true (isDisabled)
      return null;
    }
    // Otherwise, render the default remove button
    return <components.MultiValueRemove {...props} />;
  };
  const selectOptions = options
   // .filter(option => !option.isChecked) // Filter out options where isChecked is true
    .map(option => ({
      value: option.releaseId,
      label: option.name,
      isDisabled: option.isChecked?option.isChecked:option.isDisabled,
    }));

  return (
    <div className="d-flex modal-bubble-wrap" style={{ alignItems: AddStyle && 'flex-start' }}>
      <div style={{ width: "500px" }}>
        <Select
          options={selectOptions}
          onChange={handleSelectChange}
          isMulti
          value={selectedOptions.map(option => ({
            value: option.releaseId,
            label: option.name,
            isDisabled: option.isChecked
          }))}
          isSearchable
          isClearable
          placeholder={"Please Select"}
          isOptionDisabled={(option) => option.isDisabled ? true : false}
          components={{ Option: CheckboxOption, MultiValueRemove }}
          //components={{ Option: CheckboxOption }}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          menuPortalTarget={document.body} // Render the menu in the body to avoid overflow issues
          menuPosition="fixed" // Use fixed positioning for the menu
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure the menu is on top of other elements
          }}
        />
      </div>
    </div>
  );
};

export default CheckboxDropdown;

