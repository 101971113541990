import React, { useContext, useEffect, useState } from "react";
import { SprintOverviewService } from "../WebApiServices/SprintOverview.service";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";
import {
  HTTPResponse,
  MonthNames,
  ACTION_ITEM_TYPE,
  ACTION_ITEM_TYPE_TITLE,
  TASK_PRIO,
  ToastMessageType,
} from "../Helpers/Enums";
import { useParams } from "react-router-dom";
import Card from "./Overview/Card";
import { ToastMessages } from "../Helpers/HelperText";
import { SprintService } from "../WebApiServices/Sprint.service";
import ScrollPage from "../Components/ScrollPage";

const CompletedSprint = ({ onNameChange }) => {
  const { ToggleLoader, ShowToastMessage } = useContext(AppContext);
  const { LogOut } = useUIContext();
  const pageParams = useParams();
  const projectId = pageParams.projectId;
  const [completedSprintsSprintId, setCompletedSprintsSprintId] = useState([]);
  const [sprintData, setSprintData] = useState([]);
  let totalmappedData = [];
  const [projectName,setProjectName]=useState(); 

  useEffect(() => {
    if (projectName) {
      onNameChange(projectName);
    }
  }, [projectName]);
  useEffect(() => {
    GetActionItems();
  }, []);

  /* <summary>
  date: 07-02-2024
  Name: Mustakim 
  description:function to format the data coming fron data base to the desired DMY format
  <summary>*/


  const formatDate = (dateString) => {


    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const monthName = MonthNames[date.getMonth()]; // Get month name from array
    const year = date.getFullYear();
    return `${day}/${monthName}/${year}`;
  };

  //GetActionItems is used to get all the action items present in the data base

  const GetActionItems = async () => {
    try {
      ToggleLoader(true);
      const [actionItemsResponse, completedSprintsResponse] = await Promise.all(
        [
          SprintOverviewService.GetActionItems(projectId, false),
          SprintOverviewService.GetCompletedSprints(projectId),
        ]

      );
      if (
        actionItemsResponse.status === HTTPResponse.OK &&
        completedSprintsResponse.status === HTTPResponse.OK
      ) {
        // setCompletedSprintsSprintId(completedSprintsResponse.data);

        const data = completedSprintsResponse.data;
        const projectName =actionItemsResponse.data && actionItemsResponse.data[0].projectName
        //AddLocalStorageData("projectName",projectName);
        setProjectName(projectName);
        // const uniqueSprintIds = new Set();

        // const uniqueCompletedSprints = data.map((sprint) => {
        //   // Set to track unique action item IDs within the sprint
        //   const uniqueActionItemIds = new Set();

        //   // Filter actionItems to ensure uniqueness within the sprint
        //   const uniqueActionItems = sprint.actionItems.filter((item) => {
        //     // Check if the actionItemId is already in the Set
        //     if (!uniqueActionItemIds.has(item.actionItemId)) {
        //       // If not, add it to the Set and keep this action item in the filtered result
        //       uniqueActionItemIds.add(item.actionItemId);
        //       return true;
        //     }
        //     // If it is, exclude this action item from the filtered result
        //     return false;
        //   });

        //   // Return the sprint with filtered unique action items
        //   return {
        //     ...sprint,
        //     actionItems: uniqueActionItems,
        //   };
        // }).filter((sprint) => {
        //   // Ensure the sprint itself is unique by checking the sprintId
        //   if (!uniqueSprintIds.has(sprint.sprintId)) {
        //     uniqueSprintIds.add(sprint.sprintId);
        //     return true;
        //   }
        //   return false;
        // });

        // // Set the unique completed sprints to state
        // setCompletedSprintsSprintId(uniqueCompletedSprints);

        const uniqueSprintIds = new Set();

        const uniqueCompletedSprints = data
          .map((sprint) => {
            // Set to track unique keys within the sprint
            const uniqueKeys = new Set();

            // Filter actionItems to ensure uniqueness based on key within the sprint
            const uniqueActionItems = sprint.actionItems.filter((item) => {
              // Check if the key is already in the Set
              if (item.key !== null && item.key !== undefined && !uniqueKeys.has(item.key)) {
                // If not, add it to the Set and keep this action item in the filtered result
                uniqueKeys.add(item.key);
                return true;
              }
              // If it is, exclude this action item from the filtered result
              return false;
            });

            // Return the sprint with filtered unique action items
            return {
              ...sprint,
              actionItems: uniqueActionItems,
            };
          })
          .filter((sprint) => {
            // Ensure the sprint itself is unique by checking the sprintId
            if (!uniqueSprintIds.has(sprint.sprintId)) {
              uniqueSprintIds.add(sprint.sprintId);
              return true;
            }
            return false;
          });

        // Set the unique completed sprints to state
        setCompletedSprintsSprintId(uniqueCompletedSprints);

        setSprintData(actionItemsResponse.data);
        ToggleLoader(false);
      }
      if (
        actionItemsResponse.status === HTTPResponse.Unauthorized ||
        completedSprintsResponse.status === HTTPResponse.Unauthorized
      ) {
        LogOut();
      }
    } catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };
  // Group objects by sprintId

  const groupedData = sprintData.reduce((result, item) => {
    const sprintId = item.sprintId;

    if (item.status === true) {
      if (!result[sprintId]) {
        result[sprintId] = [];
      }

      result[sprintId].push(item);
    }

    return result;
  }, {});

  // Map the grouped data as needed
  const mappedData = Object.keys(groupedData).map((sprintId) => {
    const sprintData = groupedData[sprintId];

    const mappedItems = sprintData.map((item) => ({
      sprintId: item.sprintId,

      key: item.key,

      title: item.title,

      sprintName: item.sprintName,

      priorityId: item.priorityId,

      actionItemTypeID: item.actionItemTypeID,

      actionItemStatusId: item.actionItemStatusId,

      designEstimates: item.designEstimates,

      devEstimates: item.devEstimates,

      qaEstimates: item.qaEstimates,

      assigneeFirstName: item.assigneeFirstName,

      assigneeLastName: item.assigneeLastName,

      actionItemId: item.actionItemId,
    }));

    const sprint_Id = sprintData[0].sprintId;

    const sprintName = sprintData[0].sprintName;

    const startDate = sprintData[0].startDate;

    const endDate = sprintData[0].endDate;

    const releaseName = sprintData[0].releaseName;

    const itemCount = sprintData.length;

    const status = sprintData[0].status;

    const actionItemId = sprintData[0].actionItemId;

    return {
      sprint_Id: sprint_Id,

      releaseName: releaseName,

      sprintName: sprintName,

      mappedItems: mappedItems,

      startDate: startDate,

      endDate: endDate,

      itemCount: itemCount,

      actionItemId: actionItemId,

      status: status,
    };
  });

  totalmappedData = mappedData;
  // Map over the totalmappedData array and set the "completiondate" based on matching sprintIds
  const updatedSprints = totalmappedData.map((sprint) => {
    const matchingCompletedSprint = completedSprintsSprintId.find(
      (item) => item.sprintId === sprint.sprint_Id
    );
    if (matchingCompletedSprint) {
      return {
        ...sprint,
        completiondate: matchingCompletedSprint.completionDate,
      };
    } else {
      return sprint; // If no matching sprint is found, keep the sprint as is
    }
  });
  // Filter out sprints without a "completiondate" parameter
  const filteredSprints = updatedSprints.filter(
    (sprint) => sprint.completiondate !== undefined
  );

  /* <summary>
   date: 15-01-2024
   Name: MS
   description: To get Id of last coulmn to add strike off line to key and title in each page
   <summary>*/
  const [lastColumn, setLastColumn] = useState(0);
  // useEffect(()=>{
  //   getLastColumnId();
  // },[projectId])
  const getLastColumnId = async () => {
    const response = await SprintService.GetLastColumnForProjectActionItem(projectId)

    setLastColumn(response.data)

  }

  return (
    <>
      <title>Overview</title>

      {/* Header Section */}
      <div></div>
      <main className="overview completed-sprints-custom" style={{ paddingBottom: "1rem" }} >
        <section className="overview-main-block">
          <div className="">
            <div className="row">
              <div>
                <div
                  className="-content"
                  id="myTabContent"
                  style={{ minHeight: "67vh" }}
                >
                  <div
                    className="tab-pane fade active show"
                    id="overview"
                    role="tabpanel"
                    aria-labelledby="overview-tab"
                  >
                    {/* Content for the Overview tab */}
                    <div className="row">
                      <div className="container">
                        <div className="list list2">
                          <div>
                            {completedSprintsSprintId.map(
                              (sprint) =>
                                sprint.actionItems.length > 0 && (
                                  <div key={sprint.sprintId}>

                                    <div
                                      className="accordion"
                                      id={sprint.sprintId}
                                    >
                                      <div className="accordion-item">

                                        <div
                                          className="accordion-header"
                                          style={{ display: "flex" }}
                                        >

                                          <button
                                            className="accordion-button"
                                            type="button"
                                            style={{ width: "30px" }}
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#panelsStayOpen-collapse-${sprint.sprintId}`}
                                            aria-expanded="true"
                                            aria-controls={`panelsStayOpen-collapse-${sprint.sprintId}`}
                                          ></button>
                                          <button className="accordian-devstyle">
                                            <div
                                              className="row"
                                              style={{ width: "100%" }}
                                            >
                                              <div
                                                className="col-12"
                                                style={{ paddingRight: 0 }}
                                              >
                                                <div className="sprint-header-bar">
                                                  <div className="sprint-detail-initial">
                                                    <div className="sprint name">
                                                      {sprint.sprintName}

                                                    </div>

                                                    <div
                                                      key={sprint.sprintName}
                                                      className="sprint issue-count"
                                                    >
                                                      {sprint.totalTasks ===
                                                        1 ? (
                                                        <div>
                                                          {sprint.totalTasks}{" "}
                                                          issue
                                                        </div>
                                                      ) : (
                                                        <div>
                                                          {sprint.totalTasks}{" "}
                                                          issues
                                                        </div>

                                                      )}
                                                    </div>
                                                    <div className="sprint-release sprint">
                                                      {sprint.releaseName}
                                                    </div>
                                                  </div>
                                                  <div className="sprint completed-sprint">
                                                    {"Completion Date : "}
                                                    {formatDate(
                                                      sprint.completionDate
                                                    )}
                                                  </div>
                                                  {/* <div className="sprint-detail-end"></div> */}
                                                </div>
                                              </div>
                                            </div>
                                          </button>

                                        </div>

                                        <div
                                          id={`panelsStayOpen-collapse-${sprint.sprintId}`}
                                          className="accordion-collapse sprint collapse show"
                                          aria-labelledby={`panelsStayOpen-heading-${sprint.sprintId}`}
                                        >
                                          <div className="sprint-period">
                                            {formatDate(sprint.startDate)}{" "}
                                            - {formatDate(sprint.endDate)}
                                          </div>
                                          <div className="accordion-body">
                                            {sprint.actionItems.map((item) =>
                                              sprint.sprintId ===
                                                item.sprintId ? (
                                                <Card
                                                  lastColumn={item.lastActionItemStatus}
                                                  key={item.key}
                                                  item={item}
                                                  isDisabled={true}

                                                  tasktypepath={
                                                    ACTION_ITEM_TYPE[item.actionItemTypeId]
                                                  }
                                                  projectId={
                                                    pageParams.projectId
                                                  }
                                                  prioritypath={
                                                    TASK_PRIO[item.priorityId]
                                                  }
                                                  tasktitle={
                                                    ACTION_ITEM_TYPE_TITLE[
                                                    item.actionItemTypeID
                                                    ]
                                                  }
                                                  assigneeFirstName={
                                                    item.assigneeFirstName
                                                  }
                                                  assigneeLastName={
                                                    item.assigneeLastName
                                                  }
                                                  actionItemStatusId={item.actionItemStatusId}
                                                  onDragStart=""
                                                  bgcolor={item.color}
                                                />
                                              ) : null
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                            )}
                          </div>
                        </div>

                        <div
                          id="panelsStayOpen-collapseOne"
                          className="accordion-collapse backlog collapse show"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="active-sprints"
                  role="tabpanel"
                  aria-labelledby="active-sprints-tab"
                >
                  <div className="row"></div>
                </div>
                <div
                  className="tab-pane fade"
                  id="completed-sprints"
                  role="tabpanel"
                  aria-labelledby="completed-sprints-tab"
                >
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ScrollPage />
    </>
  );
};

export default CompletedSprint;
